import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  HStack,
  Text,
  useToast,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
} from '@chakra-ui/react';

import api from 'services/api';

import { ModalRootProps } from 'components/Modal/Root';

const ModalDuplicateCheckout: React.FC<ModalRootProps> = ({
  data,
  onConfirm,
  handleClose,
  ...restProps
}) => {
  const toast = useToast();

  const {
    register,
    trigger,
    getValues,
    formState: { errors },
  } = useForm({ mode: 'onChange' });

  const [saving, setSaving] = useState(false);

  const handleConfirm = async (id: number): Promise<void> => {
    const isValidated = await trigger();

    if (!isValidated) return;

    setSaving(true);

    try {
      const name = getValues('name');

      await api.post(`/dashboard/checkout/${id}/duplicate`, {
        name,
      });

      toast({
        title: 'Oferta duplicada com sucesso!',
        status: 'success',
        variant: 'solid',
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });

      if (onConfirm) onConfirm();
    } catch (err) {
      toast({
        title: 'Erro ao duplicar a oferta!',
        status: 'error',
        variant: 'solid',
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });
    } finally {
      setSaving(false);
      handleClose();
    }
  };

  return (
    <Modal {...restProps}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Duplicar oferta</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Text mb={6}>
            Ao duplicar a oferta{' '}
            <Text fontWeight="bold" as="span">
              {data.name}
            </Text>
            , você estará copiando todas as configurações desta para a nova
            oferta. Após duplicar, serão duas ofertas diferentes e poderão ser
            editadas independentemente.
          </Text>

          <FormControl isInvalid={errors?.name} isRequired>
            <FormLabel>Nome da nova oferta</FormLabel>

            <Input
              {...register('name', {
                required: 'O nome da nova oferta é obrigatório',
              })}
              type="text"
            />

            <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <HStack spacing="2">
            <Button colorScheme="red" onClick={handleClose}>
              Cancelar
            </Button>

            <Button
              colorScheme="green"
              onClick={() => handleConfirm(data.id)}
              isLoading={saving}
            >
              Duplicar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalDuplicateCheckout;
