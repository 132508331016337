import React, { useCallback, useEffect, useState } from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Skeleton,
  Box,
  Wrap,
} from '@chakra-ui/react';

import { Order } from '@types/order';

import api from 'services/api';

import { ModalRootProps } from 'components/Modal/Root';

const ModalEditCustomerData: React.FC<ModalRootProps> = ({
  data,
  onConfirm,
  handleClose,
  ...restProps
}) => {
  const [custumerData, setCustumerData] = useState<Order[]>([]);
  const [loading, setLoading] = useState(false);

  const getData = useCallback(async () => {
    setLoading(true);
    setCustumerData([]);

    try {
      const response = await api.get(`/order/${data?.id}`);

      setCustumerData(response.data.data);
    } catch (err) {
      setCustumerData([]);
    } finally {
      setLoading(false);
    }
  }, [data?.id]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleConfirm = (): void => {
    if (onConfirm) onConfirm('some data');
    handleClose();
  };

  return (
    <Modal {...restProps} scrollBehavior="inside" size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Editar cliente</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          {loading ? (
            <>
              <Skeleton mb={1} width={50} height={4} borderRadius={12} />
              <Skeleton mb={3} width="100%" height={9} borderRadius={12} />
              <Skeleton mb={1} width={50} height={4} borderRadius={12} />
              <Skeleton mb={3} width="100%" height={9} borderRadius={12} />
              <Skeleton mb={1} width={50} height={4} borderRadius={12} />
              <Skeleton mb={3} width="100%" height={9} borderRadius={12} />
              <Skeleton mb={1} width={50} height={4} borderRadius={12} />
              <Skeleton mb={3} width="100%" height={9} borderRadius={12} />
              <Skeleton mb={1} width={50} height={4} borderRadius={12} />
              <Skeleton mb={3} width="100%" height={9} borderRadius={12} />
              <Skeleton mb={1} width={50} height={4} borderRadius={12} />
              <Skeleton mb={3} width="100%" height={9} borderRadius={12} />
              <Skeleton mb={1} width={50} height={4} borderRadius={12} />
              <Skeleton mb={3} width="100%" height={9} borderRadius={12} />
            </>
          ) : (
            <Wrap justify="space-between">
              <Box width={{ base: '100%', lg: '47%' }}>
                <FormControl mb="3">
                  <FormLabel>Nome</FormLabel>
                  <Input defaultValue={custumerData.customer?.name} />
                </FormControl>
                <FormControl mb="3">
                  <FormLabel>E-mail</FormLabel>
                  <Input
                    type="email"
                    defaultValue={custumerData.customer?.email}
                  />
                </FormControl>
                <FormControl mb="3">
                  <FormLabel>Instagram</FormLabel>
                  <Input defaultValue={custumerData.customer?.instagram} />
                </FormControl>
                <FormControl mb="3">
                  <FormLabel>Documento</FormLabel>
                  <Input defaultValue={custumerData.customer?.document} />
                </FormControl>
                <FormControl mb="3">
                  <FormLabel>Telefone</FormLabel>
                  <Input defaultValue={custumerData.customer?.phone} />
                </FormControl>
                <FormControl mb="3">
                  <FormLabel>País</FormLabel>
                  <Input defaultValue={custumerData.customer?.country} />
                </FormControl>
              </Box>
              <Box width={{ base: '100%', lg: '47%' }}>
                <FormControl mb="3">
                  <FormLabel>Cidade</FormLabel>
                  <Input defaultValue={custumerData.customer?.city} />
                </FormControl>
                <FormControl mb="3">
                  <FormLabel>Estado</FormLabel>
                  <Input defaultValue={custumerData.customer?.state} />
                </FormControl>
                <FormControl mb="3">
                  <FormLabel>Endereço</FormLabel>
                  <Input defaultValue={custumerData.customer?.street_address} />
                </FormControl>
                <FormControl mb="3">
                  <FormLabel>Número</FormLabel>
                  <Input defaultValue={custumerData.customer?.street_number} />
                </FormControl>
                <FormControl mb="3">
                  <FormLabel>Bairro</FormLabel>
                  <Input defaultValue={custumerData.customer?.neighborhood} />
                </FormControl>
                <FormControl mb="3">
                  <FormLabel>Complemento</FormLabel>
                  <Input
                    defaultValue={custumerData.customer?.complementary_address}
                  />
                </FormControl>
              </Box>
            </Wrap>
          )}
        </ModalBody>

        <ModalFooter>
          <HStack spacing="2">
            <Button colorScheme="blackAlpha" onClick={handleClose}>
              Cancelar
            </Button>
            <Button colorScheme="green" onClick={handleConfirm}>
              Salvar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalEditCustomerData;
