import ModalAccountLookup from 'components/Modal/AccountLookup';
import ModalBlockOrUnblockAccountUser from 'components/Modal/BlockOrUnblockAccountUser';
import ModalBlockOrUnblockAdmins from 'components/Modal/BlockOrUnblockAdmins';
import ModalBlockOrUnblockUsers from 'components/Modal/BlockOrUnblockUsers';
import ModalChangeTransferStatus from 'components/Modal/ChangeTransferStatus';
import ModalCheckoutServiceDescription from 'components/Modal/CheckoutServiceDescription';
import ModalCheckoutWebhookHistory from 'components/Modal/CheckoutWebhookHistory';
import ModalConfirmOrderRefund from 'components/Modal/ConfirmOrderRefund';
import ModalDashboardBalanceHistory from 'components/Modal/DashboardBalanceHistory';
import ModalDefaultCheckout from 'components/Modal/DefaultCheckout';
import ModalDefaultCheckoutSort from 'components/Modal/DefaultCheckoutSort';
import ModalDefaultGiftCard from 'components/Modal/DefaultGiftCard';
import ModalDiscountCouponAdd from 'components/Modal/DiscountCouponAdd';
import ModalDiscountCouponLookup from 'components/Modal/DiscountCouponLookup';
import ModalDuplicateCheckout from 'components/Modal/DuplicateCheckout';
import ModalEditCustomerData from 'components/Modal/EditCustomerData';
import ModalEditCustomerEmail from 'components/Modal/EditCustomerEmail';
import ModalEnableOrDisableCheckout from 'components/Modal/EnableOrDisableCheckout';
import ModalMethodBalanceWithdrawRequest from 'components/Modal/MethodBalanceWithdrawRequest';
import ModalPlatformFeesHistory from 'components/Modal/PlatformFeesHistory';
import ModalProductLookup from 'components/Modal/ProductLookup';
import ModalRemoveAccount from 'components/Modal/RemoveAccount';
import ModalRemoveAccountUser from 'components/Modal/RemoveAccountUser';
import ModalRemoveAdmin from 'components/Modal/RemoveAdmin';
import ModalRemoveCheckout from 'components/Modal/RemoveCheckout';
import ModalRemoveProduct from 'components/Modal/RemoveProduct';
import ModalResendCustomerEmail from 'components/Modal/ResendCustomerEmail';
import ModalResetUserPassword from 'components/Modal/ResetUserPassword';
import ModalShowGiftCardMessage from 'components/Modal/ShowGiftCardMessage';
import ModalSplitAdd from 'components/Modal/SplitAdd';
import ModalTransferHistory from 'components/Modal/TransferHistory';
import ModalWebhookHistoryDelivery from 'components/Modal/WebhookHistoryDelivery';
import ModalWebhookPayloadViewer from 'components/Modal/WebhookPayloadViewer';

export default {
  'account-lookup': ModalAccountLookup,
  discountCouponLookup: ModalDiscountCouponLookup,
  'confirm-order-refund': ModalConfirmOrderRefund,
  'change-transfer-status': ModalChangeTransferStatus,
  'discount-coupon-add': ModalDiscountCouponAdd,
  'duplicate-checkout': ModalDuplicateCheckout,
  'edit-customer-data': ModalEditCustomerData,
  'edit-customer-email': ModalEditCustomerEmail,
  'block-or-unblock-users': ModalBlockOrUnblockUsers,
  'block-or-unblock-admin': ModalBlockOrUnblockAdmins,
  'block-or-unblock-account-user': ModalBlockOrUnblockAccountUser,
  'reset-user-password': ModalResetUserPassword,
  'remove-account-user': ModalRemoveAccountUser,
  'remove-admin': ModalRemoveAdmin,
  'remove-account': ModalRemoveAccount,
  'resend-customer-email': ModalResendCustomerEmail,
  'show-giftcard-message': ModalShowGiftCardMessage,
  'enable-or-disable-checkout': ModalEnableOrDisableCheckout,
  'remove-checkout': ModalRemoveCheckout,
  'remove-product': ModalRemoveProduct,
  'default-checkout': ModalDefaultCheckout,
  'platform-fees-history': ModalPlatformFeesHistory,
  'default-giftcard': ModalDefaultGiftCard,
  'default-checkout-sort': ModalDefaultCheckoutSort,
  splitAdd: ModalSplitAdd,
  productLookup: ModalProductLookup,
  'dashboard-balance-history': ModalDashboardBalanceHistory,
  'method-balance-withdraw-request': ModalMethodBalanceWithdrawRequest,
  'webhook-history-delivery': ModalWebhookHistoryDelivery,
  'webhook-payload-viewer': ModalWebhookPayloadViewer,
  'checkout-webhook-history': ModalCheckoutWebhookHistory,
  'transfer-history': ModalTransferHistory,
  'checkout-service-description': ModalCheckoutServiceDescription,
};
