import React from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  HStack,
  Text,
  Button,
  Flex,
} from '@chakra-ui/react';

import { ModalRootProps } from 'components/Modal/Root';

const ShowGiftCardMessage: React.FC<ModalRootProps> = ({
  data,
  onConfirm,
  handleClose,
  ...restProps
}) => {
  return (
    <Modal {...restProps}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Mensagem do cartão presente</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Flex gap={1}>
            <Text>De:</Text>
            <Text fontWeight="bold">{data.from}</Text>
          </Flex>

          <Flex gap={1} mb={3}>
            <Text whiteSpace="nowrap">Para: </Text>
            <Text fontWeight="bold" wordBreak="break-all">
              {data.to}
            </Text>
          </Flex>

          <Flex gap={1}>
            <Text fontWeight="bold">{data.message}</Text>
          </Flex>
        </ModalBody>

        <ModalFooter>
          <HStack spacing="2">
            <Button colorScheme="red" onClick={handleClose}>
              Fechar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ShowGiftCardMessage;
