import React from 'react';

import {
  Flex,
  ButtonGroup,
  Menu,
  MenuButton,
  Show,
  Hide,
  Button,
  IconButton,
  ButtonProps,
} from '@chakra-ui/react';

import { MenuList, MenuItem, MenuItemProps } from 'components/Menu';

import { SettingIcon } from 'styles/theme/components/icons';

interface ActionOption extends MenuItemProps, ButtonProps {
  title: string;
  colorScheme: string;
  type?: 'iconButton';
}

interface ActionsProps {
  options: ActionOption[];
}

const Actions: React.FC<ActionsProps> = ({ options }) => {
  if (!options) {
    return null;
  }

  return (
    <>
      <Show below="lg">
        <Menu>
          <MenuButton
            as={Button}
            leftIcon={<SettingIcon />}
            colorScheme="gray"
            size="sm"
          >
            Mais opções
          </MenuButton>

          <MenuList>
            {options.map(({ title, ...restProps }, index) => (
              <MenuItem key={`${title}_${index + 3}`} {...restProps}>
                {title}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Show>

      <Hide below="lg">
        <Flex
          as={ButtonGroup}
          size="sm"
          justifyContent={{ base: 'flex-start', lg: 'flex-end' }}
          alignItems="center"
          marginTop={{ base: 3, lg: 0 }}
          width={{ base: '100%', lg: 'auto' }}
          // gap={2}
          sx={{
            '&>*': {
              flex: '100px 1 auto',
            },
          }}
        >
          {options.map(({ title, icon, type, ...restProps }, index) =>
            type === 'iconButton' ? (
              <IconButton
                title={title}
                icon={icon}
                key={`${title}_${index + 1}`}
                {...restProps}
              />
            ) : (
              <Button
                key={`${title}_${index + 2}`}
                {...restProps}
                leftIcon={icon}
              >
                {title}
              </Button>
            ),
          )}
        </Flex>
      </Hide>
    </>
  );
};

export default Actions;
