/* eslint-disable prettier/prettier */
import React, { useState } from 'react';

import { format } from 'date-fns';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  HStack,
  Text,
  Flex,
  Input,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';

import api from 'services/api';

import { ModalRootProps } from 'components/Modal/Root';

import { toast } from 'shared/toast';

interface ModalChangeTransferStatus extends ModalRootProps {
  data: {
    type: 'cancel' | 'approve';
    id: number;
    value: number;
    created_at: Date;
  };
}

const ModalChangeTransferStatus: React.FC<ModalChangeTransferStatus> = ({
  data: { type, id, value, created_at },
  onConfirm,
  handleClose,
  ...restProps
}) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [reason, setReason] = useState<string>('');

  const handleConfirm = async (): void => {
    setIsSaving(true);

    try {
      await api.put(`/transfer/${id}`, {
        status: type === 'cancel' ? 'canceled' : 'transferred',
        ...(type === 'approve' && {
          funding_date: format(new Date(), 'yyyy-MM-dd'),
        }),
        ...(type === 'cancel' && {
          history: {
            observation: reason,
          },
        }),
      });

      toast({
        description: `Transferência ${
          type === 'cancel' ? 'cancelada' : 'aprovada'
        }  com sucesso!`,
        status: 'success',
      });

      if (onConfirm) onConfirm();
      handleClose();
    } catch (err) {
      toast({
        description: `Não foi possível ${
          type === 'cancel' ? 'cancelar' : 'aprovar'
        } essa transferência.`,
        status: 'error',
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Modal {...restProps}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>
          {type === 'cancel' ? 'Cancelar' : 'Aprovar'} transferência
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Text mb={3}>
            Você realmente deseja {type === 'cancel' ? 'cancelar' : 'aprovar'} a
            transferência?
            {type === 'approve' && ' Será debitado o saldo do cliente.'}
          </Text>

          <Flex gap={1}>
            <Text whiteSpace="nowrap">ID:</Text>

            <Text fontWeight="bold" wordBreak="break-all">
              {id || '-'}
            </Text>
          </Flex>

          <Flex gap={1}>
            <Text>Criado em:</Text>

            <Text fontWeight="bold">
              {format(new Date(created_at), 'dd/MM/yyyy HH:mm:ss')}
            </Text>
          </Flex>

          <Flex gap={1}>
            <Text whiteSpace="nowrap">Valor da transferência:</Text>

            <Text fontWeight="bold" wordBreak="break-all">
              {Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(value || 0)}
            </Text>
          </Flex>

          {type === 'cancel' && (
            <FormControl isRequired mt="5">
              <FormLabel>Motivo</FormLabel>

              <Input
                onChange={(e) => setReason(e.target.value)}
                value={reason}
              />
            </FormControl>
          )}
        </ModalBody>

        <ModalFooter>
          <HStack spacing="2">
            <Button colorScheme="red" onClick={handleClose}>
              Não
            </Button>

            <Button
              colorScheme="green"
              onClick={handleConfirm}
              isLoading={isSaving}
              isDisabled={type === 'cancel' && !reason}
            >
              Sim
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalChangeTransferStatus;
