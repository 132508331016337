import { ComponentStyleConfig } from '@chakra-ui/react';

export const Input: ComponentStyleConfig = {
  variants: {
    default: {
      field: {
        background: 'white',
        color: 'blackAplha',
        height: 12,
        borderWidth: 2,
        borderRadius: '90px', // 12
        borderColor: 'gray.100',
        errorBorderColor: 'red.300',
        transitionDuration: '100ms',
        paddingY: 5,
        _readOnly: {
          bg: `gray.100`,
        },
        _focus: {
          borderColor: 'blue.300',
        },
        _hover: {
          borderColor: 'gray.300',
        },
        _invalid: {
          borderColor: 'red.500',
          background: 'red.50',
          _placeholder: {
            color: 'red.600',
          },
        },
      },
    },
  },
  defaultProps: {
    variant: 'default',
  },
};
