import React, { useState, useCallback, useEffect } from 'react';

import { format } from 'date-fns';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Text,
  HStack,
  Select,
} from '@chakra-ui/react';

import { History } from '@types/history';
import {
  Pagination as PaginationType,
  ServicePagination,
} from '@types/pagination';

import api from 'services/api';

import Loader from 'components/Loader';
import { ModalRootProps } from 'components/Modal/Root';
import { Content } from 'components/Page';
import Pagination from 'components/Pagination';
import { Table, Thead, Tbody, Tr, Th, Td } from 'components/Table';

import useThrottledState from 'hooks/useThrottledState';

const filterConfigs = `transaction_tax,paypal_tax,installment_tax_2,installment_tax_3,installment_tax_4,installment_tax_5,installment_tax_6,installment_tax_7,installment_tax_8,installment_tax_9,installment_tax_10,installment_tax_11,installment_tax_12`;

export interface PlatformFeesHistoryProps extends ModalRootProps {
  data: {
    accountId: number;
  };
}

const ModalPlatformFeesHistory: React.FC<PlatformFeesHistoryProps> = ({
  data: initialData,
  onConfirm,
  handleClose,
  ...restProps
}) => {
  const [data, setData] = useState<History[]>([]);
  const [loading, setLoading] = useState(true);

  const [pagination, setPagination] = useThrottledState<PaginationType>(
    {
      per_page: 10,
      page: 1,
    },
    1000,
  );

  const [servicePagination, setServicePagination] = useState<ServicePagination>(
    { last_page: 1 },
  );

  const getData = useCallback(async () => {
    if (!initialData?.accountId) {
      return;
    }

    setLoading(true);
    setData([]);

    try {
      const response = await api.get('/account/configs/history', {
        params: {
          'filter[account]': initialData.accountId,
          'filter[configs]': filterConfigs,
          page: pagination.page,
          per_page: pagination.per_page,
        },
      });

      const history = response.data;

      setData(history.data);
      setServicePagination(history.meta);
    } catch (err) {
      setData([]);
      setServicePagination({ last_page: 1 });
    } finally {
      setLoading(false);
    }
  }, [pagination, initialData]);

  useEffect(() => {
    getData();
  }, [getData]);

  const renderTableData = useCallback(() => {
    return (
      <Tbody>
        {!loading &&
          data.map((item) => (
            <Tr>
              <Td>
                <Text whiteSpace="nowrap">
                  {format(new Date(item.created_at), 'dd/MM/yyyy HH:mm:ss')}
                </Text>
              </Td>

              <Td>
                <Text whiteSpace="nowrap">{item.user.name}</Text>
              </Td>

              <Td>
                <Text whiteSpace="normal">{item.message}</Text>
              </Td>
            </Tr>
          ))}

        {loading && (
          <Tr>
            <Td noData colSpan={1000}>
              <Loader />
            </Td>
          </Tr>
        )}

        {!loading && data.length === 0 && (
          <Tr>
            <Td noData colSpan={1000} textAlign="center">
              Nenhum evento encontrado.
            </Td>
          </Tr>
        )}
      </Tbody>
    );
  }, [data, loading]);

  return (
    <Modal {...restProps} scrollBehavior="inside" size="3xl">
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Histórico de eventos</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <HStack mb={3}>
            <Text fontSize="smaller">Por página:</Text>

            <Select
              width="auto"
              defaultValue={pagination.per_page}
              onChange={(e) => {
                setPagination({
                  page: 1,
                  per_page: Number(e.target.value),
                });
              }}
            >
              {[5, 10, 20, 50].map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </Select>
          </HStack>

          <Content noPadding>
            <Table>
              <Thead>
                <Tr>
                  <Th>Data</Th>

                  <Th>Usuário</Th>

                  <Th>Ação realizada</Th>
                </Tr>
              </Thead>

              {renderTableData()}
            </Table>
          </Content>
        </ModalBody>

        <ModalFooter alignItems="center" justifyContent="center" pt="0">
          <Pagination
            lastPage={servicePagination.last_page}
            onPaginate={(selectedPage) => {
              setPagination({ ...pagination, page: selectedPage });
            }}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalPlatformFeesHistory;
