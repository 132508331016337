import { ComponentStyleConfig } from '@chakra-ui/react';

export const Modal: ComponentStyleConfig = {
  baseStyle: {
    dialog: {
      borderRadius: 'base',
      margin: {
        base: '2',
        // xl: 'initial',
      },
    },
  },
};
