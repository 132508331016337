import React from 'react';

import {
  TableBodyProps,
  Tbody as ChakraTbody,
  useBreakpointValue,
  useMediaQuery,
} from '@chakra-ui/react';

import { tableBodyMediaQueryText } from 'shared/table/mediaQuery';

export const Tbody = (props: TableBodyProps): React.ReactElement => {
  const { children, ...rest } = props;

  const [isAvailableToGrid] = useMediaQuery(tableBodyMediaQueryText);

  const columnWidth = useBreakpointValue({
    base: '180px',
    xs: '180px',
    sm: '220px',
    md: '230px',
    // lg: '230px',
    // xl: '180px',
  });

  return (
    <ChakraTbody
      {...rest}
      sx={
        isAvailableToGrid && {
          '& tr': {
            display: 'grid',

            gridTemplateColumns: `repeat(auto-fit, minmax(${columnWidth}, 1fr))`,
            gridRows: 'auto auto',

            flexWrap: 'wrap',
            // justifyContent: 'space-around',
            // alignContent: 'space-evenly',
            alignItems: 'center',
            borderRadius: 'base',
            padding: 2,
            '&:nth-of-type(odd)': {
              background: 'gray.100',
              '& td': {
                bakground: 'gray.100',
              },
            },
          },
        }
      }
    >
      {children &&
        React.Children.map(children, (child, idx) => {
          return (
            React.isValidElement(child) &&
            React.cloneElement(child, {
              inBody: true,
              bodyidx: idx,
            })
          );
        })}

      {/* {React.isValidElement(children) &&
        React.cloneElement(children, { inBody: true })} */}
    </ChakraTbody>
  );
};
