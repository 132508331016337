import React, { useState } from 'react';

import { format } from 'date-fns';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  HStack,
  Text,
  Flex,
  useToast,
} from '@chakra-ui/react';

import api from 'services/api';

import { ModalRootProps } from 'components/Modal/Root';

const ModalResendCustomerEmail: React.FC<ModalRootProps> = ({
  data,
  handleClose,
  ...restProps
}) => {
  const toast = useToast();

  const [loading, setLoading] = useState(false);

  const handleConfirm = async (): Promise<void> => {
    setLoading(true);
    try {
      await api.post(`/order/${data?.orderId}/webhook/reprocess`);

      toast({
        title: 'Email reenviado com sucesso!',
        status: 'success',
        variant: 'solid',
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });
    } catch (err) {
      toast({
        title: 'Erro ao reenviar o email ao cliente!',
        status: 'error',
        variant: 'solid',
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  return (
    <Modal {...restProps}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Reenviar e-mail</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Text mb={3}>Deseja reenviar o e-mail de compra?</Text>

          <Flex gap={1}>
            <Text>Cliente:</Text>
            <Text fontWeight="bold">{data?.name}</Text>
          </Flex>

          <Flex gap={1}>
            <Text whiteSpace="nowrap">E-mail: </Text>
            <Text fontWeight="bold" wordBreak="break-all">
              {data?.email}
            </Text>
          </Flex>

          <Flex gap={1}>
            <Text>Data de compra: </Text>
            <Text fontWeight="bold">
              {format(new Date(data?.data), 'dd/MM/yyyy HH:mm:ss')}
            </Text>
          </Flex>
        </ModalBody>

        <ModalFooter>
          <HStack spacing="2">
            <Button colorScheme="red" onClick={handleClose}>
              Não
            </Button>
            <Button
              colorScheme="green"
              onClick={handleConfirm}
              isLoading={loading}
            >
              Sim
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalResendCustomerEmail;
