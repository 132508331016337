import { ComponentStyleConfig } from '@chakra-ui/react';

export const Select: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: 12,
  },
  defaultProps: {
    size: 'md',
    variant: 'default',
  },
  variants: {
    default: {
      field: {
        background: '#FFF',
        color: 'blackAplha',
        borderWidth: 2,
        borderRadius: '90px', // 12
        height: 12,
        borderColor: 'gray.100',
        transitionDuration: '100ms',
        _hover: {
          borderColor: 'gray.300',
        },
        _readOnly: {
          bg: `gray.100`,
          pointerEvents: 'none',
          touchAction: 'none',
          tabIndex: '-1',
          ariaDisabled: 'true',
        },
        _focus: {
          borderColor: 'blue.300',
        },
        _invalid: {
          borderColor: 'red.500',
          background: 'red.100',
          _placeholder: {
            color: 'red.600',
          },
        },
      },
      // addon: {
      //   borderColor: '#0f9',
      // },
    },
  },
};
