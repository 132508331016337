import React, { useState, createContext, useCallback } from 'react';

import { ModalProps as ChakraModalProps } from '@chakra-ui/react';

import { ModalKey } from 'components/Modal/Root';

type Response<T> = T;
export interface ModalProps extends ChakraModalProps {
  mode: 'edit' | 'add' | 'lookup';
  data?: { [key: string]: string | number | boolean | Date };
  onConfirm?: (...args) => Response<T>;
}

export interface Modal {
  key: ModalKey;
  props?: ModalProps;
}

type GetModalResponse<T> = {
  open(props?: T | ModalProps): void;
  close(): void;
};

export interface IModalContext {
  modals: Modal[];
  // openModal: (key: ModalKey, data?: ModalProps) => void;
  openModal<T>(key: ModalKey, data?: T & ModalProps): void;
  getModal<T>(key: ModalKey): GetModalResponse<T>;
  closeModal: (key: ModalKey) => void;
}

const ModalContext = createContext({} as IModalContext);

export const ModalProvider: React.FC = ({ children }) => {
  const [list, setList] = useState<Modal[]>([]);

  const openModal = useCallback((key: ModalKey, props?: ModalProps): void => {
    setList((oldList) => [...oldList, { key, props }]);
  }, []);

  const closeModal = useCallback((key: ModalKey): void => {
    setList((oldList) => oldList.filter((modal) => modal.key !== key));
  }, []);

  const getModal = useCallback<T>(
    (key: ModalKey): GetModalResponse<T> => {
      const open = (props?: ModalProps): void => {
        openModal(key, props);
      };

      const close = (): void => {
        closeModal(key);
      };

      return {
        open,
        close,
      };
    },
    [openModal, closeModal],
  );

  return (
    <ModalContext.Provider
      value={{
        modals: list,
        openModal,
        closeModal,
        getModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export type { ModalKey } from 'components/Modal/Root';
export default ModalContext;
