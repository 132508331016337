import { ComponentStyleConfig } from '@chakra-ui/react';

export const Checkbox: ComponentStyleConfig = {
  baseStyle: {
    // label
    control: {
      padding: 2,
      borderRadius: '50%',
    },
  },
  defaultProps: {
    colorScheme: 'blue',
  },
};
