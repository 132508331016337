import React from 'react';

import { TableHeadProps, Thead as ChakraThead } from '@chakra-ui/react';

import { tableBodyMediaQueryText } from 'shared/table/mediaQuery';

export const Thead = (props: TableHeadProps): React.ReactElement => {
  const { children, ...rest } = props;

  return (
    <ChakraThead
      {...rest}
      sx={{
        [`@media screen and ${tableBodyMediaQueryText}`]: {
          '& tr': {
            position: 'absolute',
            top: -`9999px`,
            left: -`9999px`,
            display: 'none',
          },
        },
      }}
    >
      {React.isValidElement(children) &&
        React.cloneElement(children, { inHeader: true })}
    </ChakraThead>
  );
};
