import { ComponentStyleConfig } from '@chakra-ui/react';

import { allTextVariants } from './textStyles';

export const Heading: ComponentStyleConfig = {
  ...allTextVariants,

  defaultProps: {
    variant: 'heading-1',
  },
};
