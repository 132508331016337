import React, { useState, useCallback, useEffect, useContext } from 'react';

// import { Search as SearchIcon } from '@styled-icons/boxicons-regular/Search';
import {
  Text,
  Button,
  HStack,
  Input,
  Select,
  SimpleGrid,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Tag,
  Box,
} from '@chakra-ui/react';

import { Account } from '@types/account';
import { Course } from '@types/course';

import api from 'services/api';

import Loader from 'components/Loader';
import { ModalRootProps } from 'components/Modal/Root';
import OwnSelect from 'components/OwnSelect';
import Pagination from 'components/Pagination';
import { Table, Thead, Tbody, Tr, Th, Td } from 'components/Table';

import AuthContext from 'contexts/AuthContext';

import useDebouncedState from 'hooks/useDebouncedState';
import useThrottledState from 'hooks/useThrottledState';

interface ServicePagination {
  last_page: number;
}

interface Pagination {
  per_page: number;
  page: number;
}
const ModalProductLookup: React.FC<ModalRootProps> = ({
  // data = {},
  onConfirm,
  handleClose,
  ...restProps
}) => {
  const handleConfirm = (product: Course): void => {
    if (onConfirm && typeof onConfirm === typeof Function) {
      onConfirm(product as Course);
      handleClose();
    }
  };

  const { checkRoles } = useContext(AuthContext);

  const [selectedAccount, setSelectedAccount] = useState<number | null>(null);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [accountsLoading, setAccountsLoading] = useState(false);

  const [data, setData] = useState<Course[]>([]);
  const [loading, setLoading] = useState(false);

  const [keyword, setKeyword] = useDebouncedState<string>('');
  const [pagination, setPagination] = useThrottledState<Pagination>(
    {
      per_page: 10,
      page: 1,
    },
    1000,
  );

  const [servicePagination, setServicePagination] = useState<ServicePagination>(
    { last_page: 1 },
  );

  const getData = useCallback(async () => {
    setLoading(true);
    setData([]);

    try {
      const response = await api.get('/course/wordpress', {
        params: {
          'filter[keyword]': keyword,
          'filter[type]': 'vendor',
          ...(checkRoles(['super_admin']) &&
            selectedAccount && {
              'filter[account_id]': selectedAccount,
            }),
          page: pagination.page,
          per_page: pagination.per_page,
        },
      });

      const users = response.data;

      setData(users.data);
      setServicePagination(users.meta);
    } catch (err) {
      setData([]);
      setServicePagination({ last_page: 1 });
    } finally {
      setLoading(false);
    }
  }, [keyword, checkRoles, selectedAccount, pagination]);

  const getAccount = useCallback(async () => {
    if (checkRoles(['super_admin'])) {
      setAccountsLoading(true);
      setAccounts([]);

      try {
        const response = await api.get('/account', {
          params: {
            per_page: 10000,
          },
        });

        const users = response.data;

        setAccounts(users.data);
      } catch (err) {
        setAccounts([]);
      } finally {
        setAccountsLoading(false);
      }
    }
  }, [checkRoles]);

  useEffect(() => {
    getAccount();
  }, [getAccount]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Drawer {...restProps} size="xl">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>Selecionar produto</DrawerHeader>

        <DrawerCloseButton />

        <DrawerBody>
          {checkRoles(['super_admin']) && (
            <Box marginBottom={8} maxWidth="500px">
              <OwnSelect
                placeholder="Selecione o instrutor"
                // noOptionsMessage="Nenhum instrutor encontrado"
                // value={methodToSelect.filter((method) =>
                //   selectedPaymentMethod.includes(method.value),
                // )}
                isLoading={accountsLoading}
                options={accounts.map((account) => ({
                  label: account.name,
                  value: account.id,
                }))}
                onChange={(optionsSelected) => {
                  setSelectedAccount(optionsSelected.value);
                }}
              />
            </Box>
          )}

          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={3} my={3}>
            <HStack>
              <Text fontSize="smaller">Por página:</Text>
              <Select
                width="auto"
                defaultValue={pagination.per_page}
                onChange={(e) => {
                  setPagination({
                    ...pagination,
                    per_page: Number(e.target.value),
                  });
                }}
              >
                {[5, 10, 20, 50].map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Select>
            </HStack>
            <HStack>
              <Input
                type="text"
                placeholder="Pesquisar"
                onChange={(e) => setKeyword(e.target.value)}
              />
            </HStack>
          </SimpleGrid>

          <Table>
            <Thead>
              <Tr>
                <Th>Nome do produto</Th>
                <Th>Turma</Th>
                <Th>Alunos ativos</Th>
                <Th>Ações</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!loading &&
                data.map((item) => (
                  <Tr key={item.id}>
                    <Td>
                      <Text>{item.title}</Text>

                      {checkRoles(['super_admin']) && (
                        <Text fontWeight="bold" marginTop={1}>
                          {item.author}
                        </Text>
                      )}
                    </Td>

                    <Td>Turma 6</Td>

                    <Td>
                      <Tag colorScheme="oceanBlue">{item.total_students}</Tag>
                    </Td>

                    <Td>
                      <Button
                        colorScheme="yellow"
                        size="xs"
                        onClick={() => handleConfirm(item)}
                      >
                        Selecionar
                      </Button>
                    </Td>
                  </Tr>
                ))}

              {loading && (
                <Tr>
                  <Td colSpan={10}>
                    <Loader />
                  </Td>
                </Tr>
              )}

              {!loading && data.length === 0 && (
                <Tr>
                  <Td colSpan={10} className="text-center">
                    Nenhum resultado encontrado
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </DrawerBody>

        <DrawerFooter justifyContent="center">
          <Pagination
            lastPage={servicePagination.last_page}
            onPaginate={(selectedPage) => {
              setPagination({ ...pagination, page: selectedPage });
            }}
          />
        </DrawerFooter>

        {/* <ModalFooter>
          <HStack spacing="2">
            <Button colorScheme="blackAlpha" onClick={handleClose}>
              Cancelar
            </Button>
            <Button colorScheme="green" onClick={handleConfirm}>
              Salvar
            </Button>
          </HStack>
        </ModalFooter> */}
      </DrawerContent>
    </Drawer>
  );
};

export default ModalProductLookup;
