import React, { useState } from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  HStack,
  Text,
  Flex,
  useToast,
} from '@chakra-ui/react';

import api from 'services/api';

import { ModalRootProps } from 'components/Modal/Root';

const ModalBlockOrUnblockAdmins: React.FC<ModalRootProps> = ({
  data,
  onConfirm,
  handleClose,
  ...restProps
}) => {
  const toast = useToast();

  const [loading, setLoading] = useState(false);

  const handleConfirm = async (
    id: number,
    status: 'A' | 'P' | 'B',
  ): Promise<void> => {
    setLoading(true);

    try {
      await api.put(`/user/${id}/status`, {
        status: status === 'B' ? 'A' : 'B',
      });

      toast({
        title: 'Usuário atualizado com sucesso!',
        status: 'success',
        variant: 'solid',
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });

      if (onConfirm) onConfirm();
    } catch (err) {
      toast({
        title: 'Erro ao atualizar o usuário!',
        status: 'error',
        variant: 'solid',
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  return (
    <Modal {...restProps}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {data.status === 'B' ? 'Desbloquar usuário' : 'Bloquear usuário'}
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Text mb={3}>
            {data.status === 'B'
              ? 'Deseja realmente desbloquar o usuário?'
              : 'Deseja realmente bloquear o usuário?'}
          </Text>

          <Flex gap={1}>
            <Text>Usuário:</Text>
            <Text fontWeight="bold">{data.name}</Text>
          </Flex>

          <Flex gap={1}>
            <Text whiteSpace="nowrap">E-mail: </Text>
            <Text fontWeight="bold" wordBreak="break-all">
              {data.email}
            </Text>
          </Flex>

          <Flex gap={1}>
            <Text>Data de cadastro:</Text>
            <Text fontWeight="bold">
              {new Date(data?.created_at).toLocaleDateString()}
            </Text>
          </Flex>
        </ModalBody>

        <ModalFooter>
          <HStack spacing="2">
            <Button colorScheme="red" onClick={handleClose}>
              Não
            </Button>

            <Button
              colorScheme="green"
              onClick={() => handleConfirm(data.id, data.status)}
              isLoading={loading}
            >
              Sim
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalBlockOrUnblockAdmins;
