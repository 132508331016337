import React from 'react';

import { Table as ChakraTable, TableProps } from '@chakra-ui/react';

import TableContext from 'contexts/TableContext';

import { tableBodyMediaQueryText } from 'shared/table/mediaQuery';

export const Table = (props: TableProps): React.ReactElement => {
  const { ...rest } = props;

  return (
    <TableContext.Provider value={{}}>
      <ChakraTable
        {...rest}
        sx={{
          'td .tdBefore': {
            display: 'none',
          },
          [`@media screen and ${tableBodyMediaQueryText}`]: {
            [`& table,
            & thead,
            & tbody,
            & th,
            & td,
            & tr`]: {
              display: 'block',
            },
          },
        }}
      />
    </TableContext.Provider>
  );
};
