import React from 'react';

import Box, { BoxProps } from 'components/Box';

interface ContentProps extends BoxProps {
  transparent?: boolean;
  noPadding?: boolean;
}

const Content: React.FC<ContentProps> = ({
  transparent = false,
  noPadding = false,
  ...rest
}) => {
  return (
    <Box
      marginBottom={4}
      height="100%"
      // overflow="hidden"
      {...(transparent && {
        border: 'none',
        bg: 'transparent',
      })}
      flex={1}
      {...rest}
      padding={noPadding ? undefined : '24px'}
    />
  );
};

export default Content;
