import React from 'react';

import { format } from 'date-fns';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  HStack,
  Text,
  Flex,
} from '@chakra-ui/react';

import { ModalRootProps } from 'components/Modal/Root';

const ModalConfirmOrderRefund: React.FC<ModalRootProps> = ({
  data,
  onConfirm,
  handleClose,
  ...restProps
}) => {
  const handleConfirm = (): void => {
    if (onConfirm) onConfirm();
    handleClose();
  };

  return (
    <Modal {...restProps}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Confirmação de estorno</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Text mb={3}>Você realmente deseja estornar a transação?</Text>

          <Flex gap={1}>
            <Text>Cliente:</Text>
            <Text fontWeight="bold">{data.name || '-'}</Text>
          </Flex>

          <Flex gap={1}>
            <Text whiteSpace="nowrap">E-mail:</Text>

            <Text fontWeight="bold" wordBreak="break-all">
              {data.email || '-'}
            </Text>
          </Flex>

          <Flex gap={1}>
            <Text>Data de compra:</Text>

            <Text fontWeight="bold">
              {format(new Date(data?.created_at), 'dd/MM/yyyy HH:mm:ss')}
            </Text>
          </Flex>

          <Flex gap={1}>
            <Text whiteSpace="nowrap">Valor do estorno:</Text>

            <Text fontWeight="bold" wordBreak="break-all">
              {Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(data.total)}
            </Text>
          </Flex>
        </ModalBody>

        <ModalFooter>
          <HStack spacing="2">
            <Button colorScheme="red" onClick={handleClose}>
              Não
            </Button>

            <Button colorScheme="green" onClick={() => handleConfirm()}>
              Sim
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalConfirmOrderRefund;
