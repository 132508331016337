import React from 'react';

import { MenuList as ChakraMenuList, MenuListProps } from '@chakra-ui/react';

export const MenuList: React.FC<MenuListProps> = (props) => (
  <ChakraMenuList
    borderRadius="base"
    borderColor="gray.100"
    boxShadow="light"
    padding="2"
    zIndex="999"
    {...props}
  />
);

export default MenuList;
