import React, { createContext, useCallback, useEffect } from 'react';

import { Account } from '@types/account';

import useAuth from 'hooks/useAuth';
import useModal from 'hooks/useModal';
import usePersistedState from 'hooks/usePersistedState';

export type ManagedAccount = {
  account: Account;
  handleUpdateAccount: () => void;
};

const ManagedAccountContext = createContext({} as ManagedAccount);

export const ManagedAccountProvider: React.FC = ({ children }) => {
  const auth = useAuth();

  const { openModal } = useModal();

  const [account, setAccount] = usePersistedState<Account>(
    '_managed_account',
    null,
  );

  const handleUpdateAccount = useCallback(() => {
    openModal('account-lookup', {
      onConfirm: (selectedAccount: Account) => {
        if (selectedAccount) {
          setAccount(selectedAccount);
        }
      },
    });
  }, [openModal, setAccount]);

  useEffect(() => {
    if (!auth.checkRoles(['super_admin', 'platform_support'])) {
      const userAccount = auth.user?.accounts?.at(0);
      if (userAccount) {
        setAccount(userAccount);
      }
    }
  }, [auth, setAccount]);

  return (
    <ManagedAccountContext.Provider
      value={{
        account,
        handleUpdateAccount,
      }}
    >
      {children}
    </ManagedAccountContext.Provider>
  );
};

export default ManagedAccountContext;
