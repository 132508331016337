import React, { useState, createContext, useCallback } from 'react';

import { AlertKey } from 'components/Alert/Root';

export interface AlertProps {
  type: 'info' | 'danger';
  title?: string;
  description?: string | (() => JSX.Element);
  buttons?: [string, string];
  /* eslint-disable-next-line */
  onConfirm?: (...args: any[]) => void;
}

export interface Alert {
  key: AlertKey;
  props?: AlertProps;
}

export interface IAlertContext {
  alerts: Alert[];
  openCustomAlert: (key: AlertKey, data?: AlertProps) => void;
  openAlert: (data: AlertProps) => void;
  closeAlert: (key: AlertKey) => void;
}

const AlertContext = createContext({} as IAlertContext);

export const AlertProvider: React.FC = ({ children }) => {
  const [list, setList] = useState<Alert[]>([]);

  const openCustomAlert = useCallback(
    (key: AlertKey, props?: AlertProps): void => {
      setList((oldList) => [...oldList, { key, props }]);
    },
    [],
  );

  const openAlert = useCallback(
    (props: AlertProps): void => {
      openCustomAlert('genericAlert', props);
    },
    [openCustomAlert],
  );

  const closeAlert = useCallback((key: string): void => {
    setList((oldList) => oldList.filter((alert) => alert.key !== key));
  }, []);

  return (
    <AlertContext.Provider
      value={{
        alerts: list,
        openAlert,
        openCustomAlert,
        closeAlert,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

export type { AlertKey } from 'components/Alert/Root';
export default AlertContext;
