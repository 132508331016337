import React from 'react';

import { Box as ChakraBox, BoxProps as ChakraBoxProps } from '@chakra-ui/react';

export type BoxProps = ChakraBoxProps;

const Box: React.FC<BoxProps> = (props) => {
  return (
    <ChakraBox
      borderRadius="base"
      borderWidth={1}
      borderColor="gray.100"
      padding={3}
      bg="white"
      {...props}
    />
  );
};

export default Box;
