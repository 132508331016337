import React from 'react';

import {
  Box,
  BoxProps,
  useColorModeValue,
  useMediaQuery,
} from '@chakra-ui/react';

// import { headerHeight } from 'styles/theme/config';

type HeaderProps = BoxProps;

const Header: React.FC<HeaderProps> = (props) => {
  const bgColor = useColorModeValue('white', 'white');

  const [isLowerThan414] = useMediaQuery('(max-width: 414px)');

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems={isLowerThan414 ? 'center' : 'center'}
      flexDirection={isLowerThan414 ? 'column' : 'row'}
      top="0"
      zIndex={2}
      marginBottom={2}
      paddingX="24px"
      paddingY="22px"
      fontSize="sm"
      bg={bgColor}
      borderBottomWidth="1px"
      borderColor="gray.100"
      {...props}
    />
  );
};

export default Header;
