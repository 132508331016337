import React, { createContext, useState } from 'react';

import { AvaliableTabsProps } from 'pages/Checkout/Add';

export interface Checkout {
  tabs: AvaliableTabsProps[];
  setTabs(tabs: AvaliableTabsProps[]): void;
  tabIndex: number;
  setTabIndex(index: number): void;
}

const CheckoutContext = createContext({} as Checkout);

export const CheckoutProvider: React.FC = ({ children }) => {
  const [tabs, setTabs] = useState([]);
  const [tabIndex, setTabIndex] = useState<number>(0);

  return (
    <CheckoutContext.Provider
      value={{
        tabs,
        setTabs,
        tabIndex,
        setTabIndex,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};

export default CheckoutContext;
