import { ComponentStyleConfig } from '@chakra-ui/react';

export const Badge: ComponentStyleConfig = {
  // variants: {
  //   default: {
  //     field: {
  //       borderRadius: 12,
  //     },
  //   },
  // },
  // defaultProps: {
  //   variant: 'default',
  // },

  baseStyle: {
    fontSize: 10,
    borderRadius: 12,
    px: 2,
    // py: 1,
  },
  // sizes: {
  //   xl: {
  //     // fontSize: '80px',
  //     // px: '32px',
  //   },
  // },
};
