import React from 'react';

import { Flex, Text, Box, keyframes } from '@chakra-ui/react';

interface StatusCircleProps {
  colorScheme: string;
  children: string;
  hasCircle: boolean;
  marginBottom?: string;
  hasBackground: boolean;
}

const spin = keyframes`
  0% {opacity: 0; scale: 0;}
  50% {opacity: 0.3;  scale: 1;}
  100% {opacity: 0;  scale: 0;}
`;

const StatusCircle: React.FC<StatusCircleProps> = ({
  colorScheme,
  children,
  hasCircle = true,
  marginBottom = '0',
  // hasBackground = true,
}) => {
  const spinAnimation = `${spin} infinite 2s ease`;

  const [color] = colorScheme.split('.');

  return (
    <Flex direction="row" alignItems="center" marginBottom={marginBottom}>
      {hasCircle && (
        <Box
          width={3}
          height={3}
          borderRadius="50%"
          bg={`${color}.800`}
          position="relative"
          boxShadow="base"
          flexShrink={0}
          _before={{
            content: '""',
            position: 'absolute',
            top: -1,
            left: -1,
            right: -1,
            bottom: -1,
            bg: `${color}.300`,
            opacity: 0.3,
            borderRadius: '50%',
            animation: spinAnimation,
          }}
          marginRight={2}
        />
      )}

      <Text
        display="flex"
        alignItems="center"
        justifyContent="center"
        fontSize="x-small"
        fontWeight="bold"
        color="white"
        bg={`${color}.400`}
        // padding={1}
        paddingX={2}
        borderRadius="base"
        whiteSpace="nowrap"
      >
        {children}
      </Text>
    </Flex>
  );
};

export default StatusCircle;
