const designSystemColors = {
  'neutrals-2': '#777E90',
  'blue-highlight': 'rgba(55, 114, 255, 0.1)',
  'menu-highlight': 'rgba(0, 0, 0, 0.04)',
  'menu-hover': 'rgba(247, 247, 247, 0.1)',
  system: {
    black: '#000000',
    white: '#FFFFFF',
    white2: '#FCFCFD',
  },
};

export default {
  ...designSystemColors,
  borderInWhite: '#E6E8EC',
  borderInDark: 'rgba(255, 255, 255, 0.1)',
  backgrounds: {
    primary: '#fafafa',
    white: 'rgb(252, 252, 253)',
    dark: '#11142D',
    login: {
      green: '#ecf2eb',
      yellow: '#fcf7eb',
    },
    rainbow:
      'radial-gradient(238.63% 5516.35% at -77.06% -61.42%, #FFAE47 33.93%, #FF33F7 45.13%, #8A35F8 55.63%, #7845F5 57.3%, #4A6FEC 60.65%, #00B2DF 65.3%, #0BBBDA 66.05%, #2EDACA 68.84%, #3CE6C4 70.34%), linear-gradient(0deg, #C4C4C4, #C4C4C4)',
  },
  purple: {
    100: '#E1DDFA',
    200: '#BEB7F0',
    300: '#9387E4',
    400: '#6C5DD3',
    500: '#584ABD',
    600: '#4F41B5',
    700: '#493CA7',
    800: '#433799',
    900: '#3D328B',
  },
  red: {
    50: '#FEDCDC',
    100: '#FFE7E7',
    200: '#FFB8B8',
    300: '#FF8181',
    400: '#FC5A5A',
    500: '#CF3C3C',
    600: '#C53131',
    700: '#B62D2D',
    800: '#A72A2A',
    900: '#982626',
  },
  pink: {
    100: '#FFECFA',
    200: '#FFD3F4',
    300: '#FFB6ED',
    400: '#FF98E5',
    500: '#DF6BC2',
    600: '#DB59BA',
    700: '#D646B2',
    800: '#D232AA',
    900: '#C22B9C',
  },
  yellow: {
    100: '#FFF4D8',
    200: '#FFE9AE',
    300: '#FFDA7B',
    400: '#FFC224',
    500: '#E3AF27',
    600: '#DAA41C',
    700: '#C9981A',
    800: '#B88B18',
    900: '#A87E16',
  },
  orange: {
    100: '#FFF4E9',
    200: '#FFE1C0',
    300: '#FFBB72',
    400: '#FF9F38',
    500: '#DC882F',
    600: '#D37E24',
    700: '#C27421',
    800: '#B26B1E',
    900: '#A2611B',
  },
  orangish: {
    100: '#FFEAE1',
    200: '#FFC7B0',
    300: '#FF9B72',
    400: '#FF6628',
    500: '#CF4F1A',
    600: '#C24B18',
    700: '#B34516',
    800: '#A43F14',
    900: '#953A12',
  },
  brown: {
    100: '#F1E8DD',
    200: '#C0B1A0',
    300: '#8C7255',
    400: '#65492A',
    500: '#422F1A',
    600: '#3D2C18',
    700: '#382816',
    800: '#342515',
    900: '#2F2213',
  },
  green: {
    100: '#E1FEE8',
    200: '#BDF5CA',
    300: '#7AD48E',
    400: '#4FBF67',
    500: '#43A357',
    600: '#3E9751',
    700: '#398C4B',
    800: '#348045',
    900: '#30743E',
  },
  lightGreen: {
    100: '#24FF7C',
    200: '#0FFF6F',
    300: '#00F863',
    400: '#00E25A',
    500: '#00CD52',
    600: '#00BD4C',
    700: '#00AF46',
    800: '#005020',
    900: '#00421A',
  },
  lime: {
    50: '#BEF6D0',
    100: '#ABF4C2',
    200: '#A2F2BB',
    300: '#99F1B5',
    400: '#86EFA7',
    500: '#7BED9F',
    600: '#4FE77F',
    700: '#22E15E',
    800: '#19B64B',
    900: '#138938',
  },
  blue: {
    100: '#E2EBFF',
    200: '#BFD2FF',
    300: '#78A0FF',
    400: '#3772FF',
    500: '#3266E3',
    600: '#1F59E0',
    700: '#1C52D0',
    800: '#1A4BBE',
    900: '#1844AD',
  },
  oceanBlue: {
    100: '#CAF0F8',
    200: '#ADE8F4',
    300: '#90E0EF',
    400: '#48CAE4',
    500: '#00B4D8',
    600: '#0096C7',
    700: '#0077B6',
    800: '#023E8A',
    900: '#03045E',
  },
  gray: {
    // 50: '#F7FAFC',
    50: '#F7F7F7',
    100: '#E6E6E6',
    200: '#D4D4D4',
    300: '#C2C2C2',
    400: '#B1B1B1',
    500: '#9F9F9F',
    600: '#8D8D8D',
    700: '#7C7C7C',
    800: '#343A40',
    900: '#212529',
  },

  neutral: {
    100: '#F4F5F6',
    200: '#E6E8EC',
    300: '#B1B5C3',
    400: '#777E90',
    500: '#11142D',
  },
};
