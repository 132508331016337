import React from 'react';

import { Text, Badge } from '@chakra-ui/react';

import Tooltip from 'components/Tooltip';

export const getBadgeBestOffer = (): React.ReactElement => {
  return (
    <Tooltip
      hasArrow
      label={
        <>
          <Text fontWeight="bold" marginBottom={1}>
            Oferta destaque
          </Text>

          <Text>Destaque como melhor oferta na página de vendas.</Text>
        </>
      }
    >
      <Badge
        bgGradient="linear(to-r, purple.400, pink.400)"
        color="white"
        cursor="default"
        marginLeft="1"
      >
        <Text variant="text-sm" fontSize="10px">
          Destaque
        </Text>
      </Badge>
    </Tooltip>
  );
};
