import { extendTheme, theme } from '@chakra-ui/react';
// import { mode } from '@chakra-ui/theme-tools';

import { apexChartsStyle } from './apexChartsStyle';
import { breakpoints } from './breakpoints';
import colors from './colors';
import * as components from './components';
import config from './config';
import fonts, {
  fontSizes,
  fontWeights,
  letterSpacings,
  lineHeights,
} from './fonts';
import { layerStyles } from './layerStyles';
import { shadows } from './shadows';

const customTheme = extendTheme({
  ...theme,
  config,
  colors,
  breakpoints,
  fonts,
  fontSizes,
  fontWeights,
  letterSpacings,
  lineHeights,
  layerStyles,

  styles: {
    global: () => ({
      'html, body': {
        bg: 'backgrounds.white',
      },
      '.grecaptcha-badge': {
        display: 'none',
      },
      ...apexChartsStyle,
    }),
  },

  radii: {
    none: '0',
    sm: '0.125rem',
    base: '12px',
    md: '0.375rem',
    lg: '0.5rem',
    xl: '0.75rem',
    '2xl': '1rem',
    '3xl': '1.5rem',
    full: '9999px',
  },
  transition: {
    transitionTimingFunction: 'ease',
    transitionDuration: '500ms',
  },
  shadows,
  components: { ...components },
});

export default customTheme;
