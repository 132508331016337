import React, { useState, useCallback, useEffect } from 'react';
import ReactJson from 'react-json-view';

import { format } from 'date-fns';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Text,
  Flex,
} from '@chakra-ui/react';

import { WebhookHistoryDelivery } from '@types/order';
import {
  Pagination as PaginationType,
  ServicePagination,
} from '@types/pagination';

import api from 'services/api';

import Loader from 'components/Loader';
import { ModalRootProps } from 'components/Modal/Root';
import Pagination from 'components/Pagination';
import StatusCircle from 'components/StatusCircle';
import { Table, Thead, Tbody, Tr, Th, Td } from 'components/Table';

import useThrottledState from 'hooks/useThrottledState';

interface ModalWebhookHistoryDeliveryProps extends ModalRootProps {
  data: {
    webhook_id: number;
  };
}

const ModalWebhookHistoryDelivery: React.FC<ModalWebhookHistoryDeliveryProps> = ({
  data: initialData,
  ...restProps
}) => {
  const [data, setData] = useState<WebhookHistoryDelivery[]>([]);
  const [loading, setLoading] = useState(false);

  const [pagination, setPagination] = useThrottledState<PaginationType>(
    {
      per_page: 10,
      page: 1,
    },
    1000,
  );

  const [servicePagination, setServicePagination] = useState<ServicePagination>(
    { last_page: 1 },
  );

  const getData = useCallback(async () => {
    setLoading(true);
    setData([]);

    try {
      if (initialData.webhook_id) {
        const response = await api.get(
          `/webhook/${initialData.webhook_id}/delivery`,
          {
            params: {
              page: pagination.page,
              per_page: pagination.per_page,
              sort: 'created_at',
            },
          },
        );

        const historyDelivery = response.data;

        setData(historyDelivery.data);
        setServicePagination(historyDelivery.meta);
      }
    } catch (err) {
      setData([]);
      setServicePagination({ last_page: 1 });
    } finally {
      setLoading(false);
    }
  }, [pagination, initialData.webhook_id]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Modal {...restProps} scrollBehavior="inside" size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Detalhes do evento</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Table>
            <Thead>
              <Tr>
                <Th>Data</Th>
                <Th>Situação</Th>
                <Th>Resposta</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!loading &&
                data.map((item) => {
                  const responseBody =
                    item.status !== 'error' &&
                    typeof item.response_body === 'string' &&
                    item.response_body.length > 0
                      ? JSON.parse(item.response_body)
                      : item.response_body;

                  const errorMessage = item.error_message;

                  return (
                    <Tr key={item.id}>
                      <Td>
                        {format(
                          new Date(item.created_at),
                          'dd/MM/yyyy HH:mm:ss',
                        )}
                      </Td>

                      <Td>
                        {item.status === 'success' && (
                          <StatusCircle colorScheme="green">
                            Sucesso
                          </StatusCircle>
                        )}
                        {item.status === 'error' && (
                          <StatusCircle colorScheme="red">
                            Com erro
                          </StatusCircle>
                        )}
                      </Td>

                      <Td>
                        <Flex flexDirection="column" gap="2">
                          {responseBody ? (
                            <>
                              {item.status !== 'error' ? (
                                <ReactJson
                                  displayDataTypes={false}
                                  enableClipboard={false}
                                  src={responseBody}
                                />
                              ) : (
                                responseBody
                              )}
                            </>
                          ) : (
                            <Text fontStyle="italic">Sem resposta</Text>
                          )}

                          {errorMessage && (
                            <Text color="red.400" whiteSpace="break-spaces">
                              {errorMessage}
                            </Text>
                          )}
                        </Flex>
                      </Td>
                    </Tr>
                  );
                })}

              {loading && (
                <Tr>
                  <Td colSpan={10}>
                    <Loader />
                  </Td>
                </Tr>
              )}

              {!loading && data.length === 0 && (
                <Tr>
                  <Td colSpan={10} className="text-center">
                    Nenhum resultado encontrado
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </ModalBody>

        <ModalFooter alignItems="center" justifyContent="center">
          <Pagination
            lastPage={servicePagination.last_page}
            onPaginate={(selectedPage) => {
              setPagination({ ...pagination, page: selectedPage });
            }}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalWebhookHistoryDelivery;
