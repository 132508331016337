export const documentClean: string = (document = '') => {
  return document.replace(/[^\d]+/g, '');
};

export const documentFormat: string = (document = '') => {
  const cleanedDocument = documentClean(document);

  if (cleanedDocument.length > 11) {
    return cleanedDocument.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5',
    );
  }

  return cleanedDocument.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
};
