import React, { useCallback, useState, useMemo, useEffect } from 'react';
import {
  useForm,
  SubmitHandler,
  Controller,
  RegisterOptions,
} from 'react-hook-form';
import MaskedInput from 'react-number-format';

import { format, isAfter } from 'date-fns';

import {
  Text,
  Button,
  HStack,
  Input,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Flex,
  Switch,
  RadioGroup,
  Stack,
  Radio,
  FormLabel,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';

import api from 'services/api';

import { ModalRootProps } from 'components/Modal/Root';

import { toast } from 'shared/toast';

type ModalData = {
  checkout_id: number;
  coupon_id: number;
};

interface ModalDiscountCouponAddProps extends ModalRootProps {
  data: ModalData;
}

type FormCouponData = {
  code: string;
  enabled: boolean;
  discount_type: 'amount' | 'percentage';
  discount: number;
  starts_in: string | null;
  expires_in: string | null;
  max_usage: number;
};

const discountInput = {
  percentage: {
    suffix: ' %',
    thousandSeparator: '.',
    decimalSeparator: ',',
    decimalScale: 0,
    fixedDecimalScale: 0,
  },
  amount: {
    prefix: 'R$ ',
    thousandSeparator: '.',
    decimalSeparator: ',',
    decimalScale: 2,
    fixedDecimalScale: 2,
  },
};

const ModalDiscountCouponAdd: React.FC<ModalDiscountCouponAddProps> = ({
  data,
  onConfirm,
  handleClose,
  ...restProps
}) => {
  const checkoutId = data?.checkout_id || null;
  const couponId = data?.coupon_id || null;

  const {
    handleSubmit,
    control,
    watch,
    formState,
    register,
    reset,
  } = useForm<FormCouponData>({
    mode: 'onChange',
    defaultValues: {
      code: '',
      enabled: true,
      discount_type: 'percentage',
      discount: 10,
      starts_in: null,
      expires_in: null,
      max_usage: '10',
    },
  });

  const handleConfirm = useCallback((): void => {
    if (onConfirm && typeof onConfirm === typeof Function) {
      onConfirm();
      handleClose();
    }
  }, [handleClose, onConfirm]);

  const [couponValidity, setCouponValidty] = useState('undefined');

  const watchDiscountType = watch('discount_type');

  const discountInputProps = discountInput[watchDiscountType];

  const [maxUsageIsEnabled, setMaxUsageIsEnabled] = useState(false);

  const discountInputRules = useMemo(() => {
    const rules: RegisterOptions = {
      required: 'O campo desconto é obrigatório.',
      min: {
        value: 1,
        message: `O desconto não pode ser menor do que ${
          watchDiscountType === 'percentage' ? '1%' : 'R$ 1,00'
        }`,
      },
      max: undefined,
    };

    if (watchDiscountType === 'percentage') {
      rules.max = {
        value: 99,
        message: 'O desconto não pode ser maior ou igual a 100%',
      };
    }

    return rules;
  }, [watchDiscountType]);

  const [isSaving, setIsSaving] = useState(false);

  const postCoupon = useCallback(
    async (couponData: FormCouponData) => {
      if (checkoutId || couponId) {
        setIsSaving(true);

        const starts_in = couponData.starts_in
          ? format(new Date(couponData.starts_in), 'yyyy-MM-dd HH:mm')
          : null;
        const expires_in = couponData.expires_in
          ? format(new Date(couponData.expires_in), 'yyyy-MM-dd HH:mm')
          : null;

        const couponToSave = {
          ...couponData,
          checkout_config_id: checkoutId,
          checkout_id: checkoutId,
          starts_in,
          expires_in,
          methods: [],
        };

        if (couponId) {
          couponToSave.id = couponId;
        }

        if (!maxUsageIsEnabled) {
          delete couponToSave.max_usage;
        }

        try {
          // const endpoint = couponId ? `/coupon/${couponId}` : '/coupon';

          await api.post('/coupon', couponToSave);

          toast({
            description: 'Cupom salvo com sucesso!',
            status: 'success',
          });

          return true;
        } catch (err) {
          // setLoading(false);
          toast({
            description: 'Não foi possível salvar o cupom',
            status: 'error',
          });
          return false;
        } finally {
          setIsSaving(false);
        }
      }

      return false;
    },
    [checkoutId, couponId, maxUsageIsEnabled],
  );

  const onSubmit: SubmitHandler = useCallback(
    async (formData) => {
      const postResponse = await postCoupon(formData);

      if (postResponse) {
        handleConfirm();
      }
    },
    [handleConfirm, postCoupon],
  );

  const handleFormSubmit = handleSubmit(onSubmit);

  const handleSave = useCallback(() => {
    handleFormSubmit();
  }, [handleFormSubmit]);

  useEffect(() => {
    if (couponValidity === 'undefined') {
      register('starts_in', { required: false });
    }
  }, [couponValidity, register]);

  const [coupon, setCoupon] = useState<FormCouponData>({});

  const getCoupon = useCallback(async () => {
    if (couponId) {
      setIsSaving(true);

      try {
        const response = await api.get(`/coupon/${couponId}`);

        const responseData = response.data;

        setCoupon(responseData.data);
      } catch (err) {
        // setLoading(false);
        toast({
          description: 'Não foi possível carregar o cupom',
          status: 'error',
        });
      } finally {
        setIsSaving(false);
      }
    }
  }, [couponId]);

  useEffect(() => {
    getCoupon();
  }, [getCoupon, couponId]);

  useEffect(() => {
    const hasCoupon = Object.keys(coupon).length > 0;

    if (hasCoupon) {
      const hasDate = coupon.expires_in || coupon.starts_in;

      if (hasDate) {
        setCouponValidty('date');
      }

      if (coupon.starts_in) {
        const startsIn = format(
          new Date(coupon.starts_in),
          "yyyy-MM-dd'T'HH:mm",
        );

        coupon.starts_in = startsIn;
      }

      if (coupon.expires_in) {
        const expiresIn = format(
          new Date(coupon.expires_in),
          "yyyy-MM-dd'T'HH:mm",
        );

        coupon.expires_in = expiresIn;
      }

      setMaxUsageIsEnabled(!!coupon.max_usage);

      // if (!coupon.max_usage) {
      //   delete coupon.max_usage;
      // }

      reset(coupon);
    }
  }, [coupon, reset]);

  const maxUsageRules = useMemo(() => {
    const rules: RegisterOptions = { required: maxUsageIsEnabled };

    return rules;
  }, [maxUsageIsEnabled]);

  const [watchStartsIn] = watch(['starts_in']);

  const expiresInRules = useMemo(() => {
    const startsInDate = new Date(watchStartsIn);

    const rules: RegisterOptions = {
      validate: {
        min: (value) => {
          if (value) {
            const date = new Date(value);

            return (
              isAfter(date, startsInDate) ||
              'A data de expiração deve ser maior que a data de início'
            );
          }

          return undefined;
        },
      },
    };

    return rules;
  }, [watchStartsIn]);

  return (
    <Drawer {...restProps} size="xl">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          {couponId ? 'Editar' : 'Adicionar'} cupom de desconto
        </DrawerHeader>

        <DrawerCloseButton />

        <DrawerBody>
          <Flex direction="column" marginBottom="4" color="gray.600">
            <Text>
              Crie seus cupons para dar mais vantagens para seus alunos.
            </Text>
          </Flex>

          <form onSubmit={handleFormSubmit}>
            <Flex marginY="24px" flexDirection="column" gap="8">
              <Controller
                name="enabled"
                control={control}
                defaultValue
                render={({ field }) => (
                  <Flex
                    width="100%"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Flex flexDirection="column">
                      <Text variant="text-lg">Ativar cupom</Text>
                      <Text variant="text-md" color="gray.600">
                        Ative para aceitar desconto a partir desse cupom
                      </Text>
                    </Flex>

                    <Switch
                      size="lg"
                      isChecked={field.value}
                      onChange={(checked) => field.onChange(checked)}
                    />
                  </Flex>
                )}
              />

              <Flex width="100%" flexDirection="column" gap="2">
                <FormControl isRequired isInvalid={formState.errors?.code}>
                  <FormLabel>Código do cupom</FormLabel>

                  <Controller
                    name="code"
                    control={control}
                    rules={{
                      required: 'Código do cupom é obrigatório',
                      minLength: {
                        value: 5,
                        message: 'O códido deve ter pelo menos 5 caracteres',
                      },
                    }}
                    render={({ field }) => <Input {...field} maxLength={15} />}
                  />
                  <FormErrorMessage>
                    {formState.errors?.code?.message}
                  </FormErrorMessage>
                </FormControl>
              </Flex>

              <Flex
                width="100%"
                alignItems="center"
                justifyContent="space-between"
                flexWrap="wrap"
                gap="3"
              >
                <Flex flexDirection="column">
                  <Text variant="text-lg">Validade do cupom</Text>
                  <Text variant="text-md" color="gray.600">
                    Informe a validade do cupom
                  </Text>
                </Flex>

                <RadioGroup
                  value={couponValidity}
                  onChange={(value) => setCouponValidty(value)}
                >
                  <Stack spacing="6" direction="row">
                    <Radio value="undefined">Indeterminada</Radio>
                    <Radio value="date">Início e término</Radio>
                  </Stack>
                </RadioGroup>
              </Flex>

              {couponValidity === 'date' && (
                <Flex width="100%" flexDirection="row" flexWrap="wrap" gap="4">
                  <Flex flex="1" flexDirection="column" gap="2">
                    <FormControl
                      isRequired
                      isInvalid={formState.errors?.starts_in}
                    >
                      <FormLabel>Data início</FormLabel>
                      <Controller
                        name="starts_in"
                        rules={{ required: couponValidity === 'date' }}
                        control={control}
                        render={({ field }) => (
                          <Input type="datetime-local" {...field} />
                        )}
                      />
                      <FormErrorMessage>
                        Data de início é obrigatória
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>

                  <Flex flex="1" flexDirection="column" gap="2">
                    <FormControl isInvalid={formState.errors?.expires_in}>
                      <FormLabel>Data término (opicional)</FormLabel>

                      <Controller
                        name="expires_in"
                        rules={expiresInRules}
                        control={control}
                        render={({ field }) => (
                          <Input
                            type="datetime-local"
                            {...field}
                            min={watchStartsIn}
                          />
                        )}
                      />

                      <FormErrorMessage>
                        {formState.errors?.expires_in?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>
                </Flex>
              )}

              <Flex width="100%" flexDirection="column">
                <FormControl
                  isRequired
                  isInvalid={
                    formState.errors?.discount_type ||
                    formState.errors?.discount
                  }
                >
                  <FormLabel>Desconto</FormLabel>

                  <Flex
                    gap={3}
                    width="100%"
                    alignItems={{ base: 'flex-start', sm: 'center' }}
                    justifyContent="space-between"
                    // flexDirection={{ base: 'column', sm: 'row' }}
                    flexWrap="wrap"
                  >
                    <Flex flex="1">
                      <Controller
                        name="discount_type"
                        rules={{ required: true }}
                        control={control}
                        defaultValue="percentage"
                        render={({ field }) => (
                          <RadioGroup
                            {...field}
                            // value={field.value}
                            // onChange={(value) => field.onChange(value)}
                            width="100%"
                          >
                            <Stack spacing="6" direction="row">
                              <Radio value="percentage">Porcentagem (%)</Radio>
                              <Radio value="amount">Valor (R$)</Radio>
                            </Stack>
                          </RadioGroup>
                        )}
                      />
                    </Flex>

                    <Flex width={{ base: '100%', sm: 'auto' }}>
                      <Controller
                        name="discount"
                        rules={discountInputRules}
                        control={control}
                        render={({ field }) => (
                          <MaskedInput
                            value={field.value}
                            onValueChange={(value) => {
                              field.onChange(value.floatValue);
                            }}
                            displayType="input"
                            type="tel"
                            {...discountInputProps}
                            customInput={Input}
                            textAlign="right"
                            allowNegative={false}
                          />
                        )}
                      />
                    </Flex>
                  </Flex>

                  <FormErrorMessage>
                    {formState.errors.discount?.message}
                  </FormErrorMessage>
                </FormControl>
              </Flex>

              <FormControl isRequired isInvalid={formState.errors?.max_usage}>
                <Flex
                  width="100%"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Flex flexDirection="column">
                    <Text variant="text-lg">Limitar uso do cupom?</Text>
                    <Text variant="text-md" color="gray.600">
                      Informe a quantidade de vezes que o cupom poderá ser
                      aplicado
                    </Text>
                  </Flex>

                  <Flex alignItems="center" gap="2">
                    <Switch
                      size="lg"
                      isChecked={maxUsageIsEnabled}
                      onChange={(e) => setMaxUsageIsEnabled(e.target.checked)}
                    />

                    <Controller
                      name="max_usage"
                      rules={maxUsageRules}
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          type="number"
                          isReadOnly={!maxUsageIsEnabled}
                          min="1"
                        />
                      )}
                    />
                  </Flex>
                </Flex>

                <FormErrorMessage>
                  Informe uma quantidade máxima de uso
                </FormErrorMessage>
              </FormControl>
            </Flex>
          </form>
        </DrawerBody>

        <DrawerFooter>
          <HStack spacing="2">
            <Button colorScheme="blackAlpha" onClick={handleClose}>
              Cancelar
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleSave}
              isLoading={isSaving}
            >
              Salvar
            </Button>
          </HStack>
        </DrawerFooter>

        {/* <ModalFooter>
          <HStack spacing="2">
            <Button colorScheme="blackAlpha" onClick={handleClose}>
              Cancelar
            </Button>
            <Button colorScheme="green" onClick={handleConfirm}>
              Salvar
            </Button>
          </HStack>
        </ModalFooter> */}
      </DrawerContent>
    </Drawer>
  );
};

export default ModalDiscountCouponAdd;
