import { useContext } from 'react';

import ManagedAccountContext, {
  ManagedAccount,
} from 'contexts/ManagedAccountContext';

function useManagedAccount(): ManagedAccount {
  const accountContext = useContext(ManagedAccountContext);

  if (!accountContext) {
    throw new Error(
      'You have to use "useManagedAccount()" inside ManagedAccountProvider',
    );
  }

  return accountContext;
}

export default useManagedAccount;
