import React, { useState, useCallback, useMemo, useEffect } from 'react';
import MaskedInput from 'react-number-format';

import { SearchIcon } from '@chakra-ui/icons';
import {
  Text,
  Button,
  HStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightElement,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Flex,
  VStack,
  Skeleton,
  IconButton,
} from '@chakra-ui/react';

import { Account } from '@types/account';

import api from 'services/api';

import Loader from 'components/Loader';
import { ModalRootProps } from 'components/Modal/Root';

import { toast } from 'shared/toast';

type Result = 'clean' | 'not_found' | 'founded';

type SplitData = {
  checkout_id: number;
  coproduction_id: number;
};

interface ModalSplitAddProps extends ModalRootProps {
  data: SplitData;
  hasCoproductors: boolean;
  ownerEmail: string;
}

const isValidEmail = (email: string): boolean => {
  return /\S+@\S+\.\S+/.test(email);
};

const ModalSplitAdd: React.FC<ModalSplitAddProps> = ({
  data,
  hasCoproductors,
  ownerEmail,
  onConfirm,
  ...restProps
}) => {
  const handleConfirm = useCallback((): void => {
    if (onConfirm && typeof onConfirm === typeof Function) {
      onConfirm();
      // handleClose();
    }
  }, [onConfirm]);

  const { checkout_id, coproduction_id } = data;

  const [searchKeyword, setSearchKeyword] = useState(
    hasCoproductors ? '' : ownerEmail,
  );

  const [hasResults, setHasResults] = useState<Result>('clean');

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [account, setAccount] = useState<Account>(null);

  const [splitPercentage, setSplitPercentage] = useState(null);

  const [isMain, setIsMain] = useState(false);

  const [availablePercentage, setAvailablePercentage] = useState(0);
  const [loadingAvailablePercentage, setLoadingAvailablePercentage] = useState(
    false,
  );

  const searchAccount = useCallback(async () => {
    if (searchKeyword) {
      setIsLoading(true);

      const searchParams = {
        params: {
          email: searchKeyword,
        },
      };
      try {
        const response = await api.get(
          '/dashboard/checkout/coproduction/account',
          searchParams,
        );

        const responseData = response.data?.data || [];

        if (responseData) {
          setAccount(responseData);
        }

        setHasResults(responseData ? 'founded' : 'not_found');
      } catch (err) {
        setHasResults('not_found');
        // toast({
        //   description: 'Não foi possível carregar as configurações do checkout',
        //   status: 'error',
        // });
      } finally {
        setIsLoading(false);
      }
    }
  }, [searchKeyword]);

  const handleSearch = useCallback(() => {
    if (searchKeyword.length > 0 && isValidEmail(searchKeyword)) {
      searchAccount();
    }
  }, [searchKeyword, searchAccount]);

  useEffect(() => {
    if (!hasCoproductors) searchAccount();
  }, [hasCoproductors, searchAccount]);

  const handleKeywordChange = useCallback((value: string) => {
    setSearchKeyword(value);

    if (value.length === 0) {
      setHasResults('clean');
    }
  }, []);

  const handleChangePercentage = useCallback((value: number) => {
    setSplitPercentage(value ?? 0);
  }, []);

  const isValidKeyword = searchKeyword ? isValidEmail(searchKeyword) : true;

  const checkPercentage = useMemo(() => {
    if (
      (splitPercentage <= 0 || splitPercentage > 100) &&
      splitPercentage !== null
    ) {
      return {
        error: true,
        message: 'O valor deve ser maior que 0 e menor que 100',
      };
    }

    // if (splitPercentage > availablePercentage) {
    //   return {
    //     error: true,
    //     message: 'O valor deve ser menor ou igual ao valor disponível',
    //   };
    // }

    return { error: false };
  }, [splitPercentage]);

  const postCoproduction = useCallback(async () => {
    if (checkout_id && account && !checkPercentage.error) {
      setIsLoading(true);
      // const userAccount =
      //   auth.user?.accounts?.length > 0 ? auth.user?.accounts[0] : {};

      const coproductionData = {
        account_id: account.id,
        value: splitPercentage,
        type: 'percentage',
        is_main: isMain,
      };

      if (coproduction_id) {
        coproductionData.id = coproduction_id;
      }

      try {
        const endpoint = `/dashboard/checkout/${checkout_id}/coproduction`;

        await api.post(endpoint, coproductionData);

        toast({
          description: 'Co-produção salva com sucesso!',
          status: 'success',
        });
        handleConfirm();
      } catch (err) {
        toast({
          description: 'Não foi possível salvar os dados da co-produção',
          status: 'error',
        });
      } finally {
        setIsLoading(false);
      }
    }
  }, [
    checkout_id,
    account,
    checkPercentage.error,
    splitPercentage,
    isMain,
    coproduction_id,
    handleConfirm,
  ]);

  const getCoproduction = useCallback(async () => {
    if (coproduction_id && checkout_id) {
      setIsLoading(true);

      try {
        const response = await api.get(
          `/dashboard/checkout/${checkout_id}/coproduction/${coproduction_id}`,
        );

        const responseData = response.data?.data || {};

        if (responseData.account) {
          setAccount(responseData.account);
        }

        if (responseData.value) {
          setSplitPercentage(responseData.value);
          setHasResults('founded');
          setIsMain(responseData.is_main);
        }
      } catch (err) {
        setHasResults('not_found');
        toast({
          description: 'Não foi possível carregar os dados do co-produtor',
          status: 'error',
        });
      } finally {
        setIsLoading(false);
      }
    }
  }, [coproduction_id, checkout_id]);

  useEffect(() => {
    getCoproduction();
  }, [getCoproduction]);

  const getAvailablePercentage = useCallback(async () => {
    if (checkout_id) {
      setLoadingAvailablePercentage(true);

      try {
        const response = await api.get(
          `/dashboard/checkout/${checkout_id}/coproduction/percentage`,
        );

        const responseData = response.data || {};

        setAvailablePercentage(responseData?.available_percentage || 0);
      } catch (err) {
        toast({
          description: 'Não foi possível carregar os dados do co-produtor',
          status: 'error',
        });
      } finally {
        setLoadingAvailablePercentage(false);
      }
    }
  }, [checkout_id]);

  useEffect(() => {
    if (account) {
      getAvailablePercentage();
    }
  }, [account, getAvailablePercentage]);

  return (
    <Drawer {...restProps} size="md">
      <DrawerOverlay />

      <DrawerContent borderTopLeftRadius="base" borderBottomLeftRadius="base">
        <DrawerHeader>
          {hasCoproductors
            ? `${coproduction_id ? 'Editar' : 'Novo'} co-produtor`
            : 'Adicionar valor padrão'}
        </DrawerHeader>

        <DrawerCloseButton />

        <DrawerBody>
          <Flex direction="column" marginBottom="4" color="gray.600">
            {hasCoproductors ? (
              <Text>
                {coproduction_id
                  ? 'Altere as informações de co-produção do seu parceiro'
                  : 'Informe o e-mail do seu parceiro cadastrado na Cursology.'}
              </Text>
            ) : (
              <Text>
                Antes de adicionar um co-produtor será necessário informar a %
                padrão do dono do produto.
              </Text>
            )}
          </Flex>

          {hasCoproductors && (
            <FormControl
              isInvalid={!isValidKeyword}
              marginBottom="4"
              hidden={!!coproduction_id}
            >
              <Flex
                alignItems="center"
                gap="2"
                flexDirection={{ base: 'column', sm: 'row' }}
              >
                <InputGroup>
                  <Input
                    type="email"
                    placeholder="E-mail do parceiro"
                    value={searchKeyword}
                    onChange={(e) => handleKeywordChange(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
                  />

                  <InputRightElement width="auto" marginRight="1">
                    <IconButton
                      icon={<SearchIcon />}
                      onClick={handleSearch}
                      isLoading={isLoading}
                      isDisabled={!isValidKeyword}
                    />
                  </InputRightElement>
                </InputGroup>
              </Flex>

              <FormErrorMessage marginLeft="2">
                Informe um e-mail válido
              </FormErrorMessage>
            </FormControl>
          )}

          {hasResults !== 'founded' && (
            <Flex
              direction="column"
              justifyContent="center"
              marginY={8}
              marginTop="16"
            >
              <Flex
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Flex
                  bg="gray.50"
                  width="100px"
                  height="100px"
                  padding="4"
                  borderRadius="full"
                  alignItems="center"
                  justifyContent="center"
                >
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <SearchIcon w="10" h="10" color="gray.300" />
                  )}
                </Flex>

                <Text marginTop="4" color="gray.700">
                  {hasResults === 'clean' &&
                    'Utilize o campo acima para pesquisar'}
                  {hasResults === 'not_found' && 'Nenhum resultado encontrado'}
                </Text>
              </Flex>
            </Flex>
          )}

          {hasResults === 'founded' && (
            <VStack
              alignItems="flex-start"
              spacing="2"
              marginY={hasCoproductors ? '8' : '0'}
            >
              {hasCoproductors && (
                <Text fontSize="xl">Parceiro encontrado</Text>
              )}

              <Flex
                bg="gray.50"
                padding="4"
                borderRadius="base"
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <VStack alignItems="flex-start" spacing="1">
                  <Text fontSize="md" fontWeight="bold">
                    {account.name}
                  </Text>
                  <Text fontStyle="italic">{account.email}</Text>
                </VStack>

                {/* <Button
                  size="sm"
                  colorScheme="yellow"
                  onClick={() => setIsSelected(true)}
                >
                  Configurar
                </Button> */}
              </Flex>

              <Flex width="100%">
                <FormControl
                  marginTop="4"
                  isRequired
                  isInvalid={checkPercentage.error}
                >
                  <FormLabel>% do split</FormLabel>

                  <MaskedInput
                    suffix=" %"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale="2"
                    fixedDecimalScale="2"
                    defaultValue={0}
                    type="tel"
                    customInput={Input}
                    textAlign="right"
                    allowNegative={false}
                    value={splitPercentage === null ? 0 : splitPercentage}
                    onValueChange={(value) =>
                      handleChangePercentage(value.floatValue)
                    }

                    // isAllowed={(values) => {
                    //   const { floatValue, formattedValue } = values;
                    //   return formattedValue === '' || floatValue <= 100;
                    // }}
                  />
                  <FormErrorMessage>{checkPercentage.message}</FormErrorMessage>
                </FormControl>
              </Flex>

              <Flex
                width="100%"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
                <Text
                  color="gray.600"
                  fontSize="small"
                  textTransform="uppercase"
                  fontWeight="semibold"
                  marginRight="2"
                >
                  Disponível:
                </Text>

                {loadingAvailablePercentage && (
                  <Skeleton width="60px" height="30px" borderRadius="base" />
                )}

                {!loadingAvailablePercentage && (
                  <Text
                    fontWeight="bold"
                    fontSize="2xl"
                    bg="blue.400"
                    color="white"
                    padding="2"
                    borderRadius="base"
                  >
                    {availablePercentage}%
                  </Text>
                )}
              </Flex>
            </VStack>
          )}
        </DrawerBody>

        {account && (
          <DrawerFooter justifyContent="flex-end">
            <HStack>
              {/* <Button
                paddingX="10"
                colorScheme="red"
                onClick={() => {
                  setIsSelected(false);
                  setHasResults(false);
                }}
              >
                Cancelar
              </Button> */}

              <Button
                paddingX="10"
                colorScheme="yellow"
                onClick={postCoproduction}
                isLoading={isLoading}
                isDisabled={checkPercentage.error || splitPercentage === null}
              >
                Finalizar
              </Button>
            </HStack>
          </DrawerFooter>
        )}
      </DrawerContent>
    </Drawer>
  );
};

export default ModalSplitAdd;
