import { ComponentStyleConfig } from '@chakra-ui/react';

export const FormLabel: ComponentStyleConfig = {
  baseStyle: {
    fontSize: '12px',
    lineHeight: '12px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: 'gray.600',
    _invalid: {
      color: 'red.500',
    },
  },
};

export const FormError: ComponentStyleConfig = {
  baseStyle: {
    text: {
      color: 'red.500',
      mb: 3,
    },
  },
};
