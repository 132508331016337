import React from 'react';
import ReactSelect, { Props as ReactSelectProps } from 'react-select';

import { Box } from '@chakra-ui/react';

import DropdownIndicator from './components/DropdownIndicator';
import LoadingIndicator from './components/LoadingIndicator';

const OwnSelect: React.FC<ReactSelectProps> = (props) => {
  return (
    <Box
      sx={{
        '& .react-select__control': {
          zIndex: 2,
          borderRadius: '90px', // 12
          borderColor: 'gray.100',
          borderWidth: 2,
          minHeight: 12,
          paddingX: '0.5rem',
          boxShadow: 'none',

          // '&.react-select__control--is-disabled': {
          //   borderColor: 'blue.300',
          // },
          // _hover: {
          //   borderColor: 'gray.300',
          // },
          '&.react-select__control--is-focused': {
            borderColor: 'blue.300',
          },
          // '&.react-select__control--menu-is-open': {
          //   borderColor: 'blue.300',
          // },

          '& .react-select__value-container': {
            '& .react-select__multi-value': {
              borderRadius: 6,
              overflow: 'hidden',

              bg: 'gray.100',

              '& .react-select__multi-value__label': {
                paddingX: 2,
                // fontSize: 12,
              },
              '& .react-select__multi-value__remove': {
                bg: 'gray.300',
                _hover: {
                  bg: 'red.100',
                  color: 'red.900',
                  cursor: 'pointer',
                },
              },
            },
          },
        },
        '& .react-select__menu': {
          borderRadius: 12,
          overflow: 'hidden',
          // width: '100%',
          // bg: 'red',
          overflow: 'hidden',
          // width: 'calc(100% - 2px)',
          // marginLeft: '1px',

          // marginTop: -2,
          // borderTopRightRadius: 0,
          // borderTopLeftRadius: 0,
          // paddingTop: 4,
          zIndex: 3,

          outline: 0,
          borderWidth: 2,
          boxShadow: 'dropdown',

          borderColor: 'gray.100',

          '& .react-select__menu-list': {
            padding: 2,

            '& .react-select__option': {
              borderRadius: 12,

              transition: 'all 300ms ease',
              bg: 'transparent',
              color: 'blackAlpha.800',

              '&.react-select__option--is-focused': {
                bg: 'gray.50',
                // color: 'blue.400',
                cursor: 'pointer',
              },
            },
          },
        },
      }}
    >
      <ReactSelect
        {...props}
        components={{
          DropdownIndicator,
          LoadingIndicator,
          IndicatorSeparator: null,
        }}
        classNamePrefix="react-select"
      />
    </Box>
  );
};

export default OwnSelect;
