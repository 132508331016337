import React, { useCallback, useEffect, useState } from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Skeleton,
  useToast,
} from '@chakra-ui/react';

import api from 'services/api';

import { ModalRootProps } from 'components/Modal/Root';

const ModalEditCustomerEmail: React.FC<ModalRootProps> = ({
  data,
  onConfirm,
  handleClose,
  ...restProps
}) => {
  const toast = useToast();

  const [customerName, setCustomerName] = useState();
  const [customerEmail, setCustomerEmail] = useState();
  const [loading, setLoading] = useState(false);

  const getData = useCallback(async () => {
    setLoading(true);

    try {
      const response = await api.get(`/customer/${data?.customerId}`);

      const custumerData = response.data.data;

      setCustomerName(custumerData.name);
      setCustomerEmail(custumerData.email);
    } catch (err) {
      toast({
        title: 'Ocorreu um erro ao buscar os dados do cliente!',
        status: 'error',
        variant: 'solid',
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.customerId]);

  const handleConfirm = async (): Promise<void> => {
    setLoading(true);

    try {
      await api.put(`/customer/${data?.customerId}`, {
        name: customerName,
        email: customerEmail,
      });

      toast({
        title: 'Cliente editado com sucesso!',
        status: 'success',
        variant: 'solid',
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });

      handleClose();

      await api.post(`/order/${data?.orderId}/webhook/change-customer-email`);

      if (onConfirm) onConfirm();
    } catch (err) {
      toast({
        title: 'Erro ao editar o cliente!',
        status: 'error',
        variant: 'solid',
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Modal {...restProps}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Editar cliente</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          {loading ? (
            <>
              <Skeleton mb={1} width={50} height={4} borderRadius={12} />
              <Skeleton mb={3} width="100%" height={9} borderRadius={12} />
              <Skeleton mb={1} width={50} height={4} borderRadius={12} />
              <Skeleton mb={3} width="100%" height={9} borderRadius={12} />
            </>
          ) : (
            <>
              <FormControl mb="3">
                <FormLabel>Nome</FormLabel>
                <Input
                  type="text"
                  defaultValue={customerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                />
              </FormControl>
              <FormControl mb="3">
                <FormLabel>E-mail</FormLabel>
                <Input
                  type="email"
                  defaultValue={customerEmail}
                  onChange={(e) => setCustomerEmail(e.target.value)}
                />
              </FormControl>
            </>
          )}
        </ModalBody>

        <ModalFooter>
          <HStack spacing="2">
            <Button colorScheme="blackAlpha" onClick={handleClose}>
              Cancelar
            </Button>

            <Button
              colorScheme="green"
              onClick={handleConfirm}
              isLoading={loading}
            >
              Salvar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalEditCustomerEmail;
