import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  useToast,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
} from '@chakra-ui/react';

import api from 'services/api';

import { ModalRootProps } from 'components/Modal/Root';

export interface ModalCheckoutServiceDescriptionProps extends ModalRootProps {
  data: {
    checkout_id: number;
    name: string;
  };
}

const ModalCheckoutServiceDescription: React.FC<ModalCheckoutServiceDescriptionProps> = ({
  data,
  onConfirm,
  handleClose,
  ...restProps
}) => {
  const toast = useToast();

  const {
    register,
    trigger,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: data.name ?? '',
    },
  });

  const [saving, setSaving] = useState(false);

  const handleConfirm = async (): Promise<void> => {
    const isValidated = await trigger();

    if (!isValidated) return;

    if (!data.checkout_id) {
      return;
    }

    setSaving(true);

    try {
      const name = getValues('name');

      await api.post(
        `/dashboard/checkout/${data.checkout_id}/service-description`,
        {
          tax_invoice_service_description: name,
        },
      );

      toast({
        title: 'Descrição salva com sucesso!',
        status: 'success',
        variant: 'solid',
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });

      if (onConfirm) onConfirm();
      handleClose();
    } catch (err) {
      toast({
        title: 'Erro ao salvar a descrição!',
        status: 'error',
        variant: 'solid',
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });
    } finally {
      setSaving(false);
    }
  };

  return (
    <Modal {...restProps}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Descrição do serviço</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Text mb={6}>
            Informe como os serviços serão discriminados na nota fiscal. Por
            padrão, o nome da oferta será enviado.
          </Text>

          <FormControl>
            <FormLabel>Descrição do serviço</FormLabel>

            <Input
              {...register('name', {
                required: 'O nome da nova oferta é obrigatório',
              })}
              type="text"
            />

            <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="green"
            onClick={() => handleConfirm()}
            isLoading={saving}
          >
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalCheckoutServiceDescription;
