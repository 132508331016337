import { CSSObject } from '@chakra-ui/react';

export const apexChartsStyle: CSSObject = {
  '.apexcharts-tooltip': {
    background: 'white !important',
    borderRadius: '12px !important',
    border: '1px solid rgba(0, 0, 0, 0.1)',

    '& .apexcharts-tooltip-text .apexcharts-tooltip-y-group': {
      display: 'flex',
      flexDirection: 'column',
      fontWeight: 'bold',
      // '& .apexcharts-tooltip-text-y-value',
      '& .apexcharts-tooltip-text-y-value': {
        marginLeft: 0,
      },
    },

    '& .custom-tooltip': {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      bg: 'white',
      color: 'gray.800',
      fontSize: '12px',
      padding: 2,

      '& .category': {
        fontWeight: 'bold',
        marginX: '1',
        marginY: 0.5,
        marginBottom: '2',
        bg: 'gray.50',
        borderRadius: 'base',
        paddingX: '1',
        paddingY: 0.5,
        textAlign: 'center',
      },

      '& .title': {
        fontWeight: 'bold',
        marginBottom: '1',
        paddingX: '1',
        paddingY: 0.5,

        '& .title-legend': {
          display: 'inline-block',
          width: '10px',
          height: '10px',
          borderRadius: '5px',
          marginRight: 1,
          background: 'blue',
        },
      },

      '& .line': {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '2',
        paddingX: 1,
        paddingY: 0.5,

        '&:not(:last-child)': {
          borderBottomWidth: '1px',
          borderColor: 'blackAlpha.50',
        },
        // fontWeight: 'bold',
        // marginBottom: '1',

        '& .value': {
          fontWeight: 'semibold',
        },
      },
    },
  },
};
