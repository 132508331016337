import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { Auth } from '@types/auth';
import { Roles } from '@types/user';

import api from 'services/api';

import usePersistedState from 'hooks/usePersistedState';

import { toast } from 'shared/toast';

export type { Auth } from '@types/auth';

const LocalStorageKey = 'cursology:auth';

const AuthContext = createContext({} as Auth);

interface AccountsAuth {
  id: number;
  name: string;
  roles: Roles[];
}

export interface UserAuth {
  id: number;
  name: string;
  email: string;
  status: string;
  created_at: string;
  updated_at: string;
  roles: Roles[];
  accounts: AccountsAuth[];
}

export const AuthProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const [loading] = useState<boolean>(false);
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [token, setToken] = usePersistedState<string | null>(
    LocalStorageKey,
    null,
  );

  const [validing, setValiding] = useState<boolean>(true);

  const [user, setUser] = useState<UserAuth>({} as UserAuth);

  const getAuth = useCallback(async () => {
    setValiding(true);

    try {
      const { data: userInfo } = await api.get('/auth');

      const loadedUser: UserAuth = userInfo.data;

      const isNewUser =
        loadedUser.roles.length === 0 && loadedUser.accounts.length === 0;

      const userData = {
        ...loadedUser,
        ...(isNewUser && { roles: ['default'] }),
      };

      setUser(userData);

      setAuthenticated(true);
    } catch (err) {
      setAuthenticated(false);

      delete api.defaults.headers.Authorization;

      toast({
        description: 'Sessão expirada! Faça login novamente.',
        status: 'warning',
      });

      // history.push('/login');
    } finally {
      setValiding(false);
    }
  }, []);

  const handleLogout = useCallback(
    (force?: boolean): void => {
      setValiding(true);

      try {
        if (!force) {
          api.delete('/auth');
        }
      } finally {
        setValiding(false);

        setAuthenticated(false);

        setToken(null);

        setUser({} as UserAuth);

        delete api.defaults.headers.Authorization;

        // history.push('/login');
      }
    },
    [setToken],
  );

  const handleLogin = useCallback(
    async (email: string, password: string): Promise<void> => {
      try {
        const gAction = 'pay_login';
        const gToken = await grecaptcha.execute(
          process.env.REACT_APP_CAPTCHA_TOKEN,
          { action: gAction },
        );

        const authUser = await api.post(
          '/auth',
          {
            email,
            password,
          },
          {
            headers: {
              'X-G-Token': gToken,
              'X-G-Action': gAction,
              'X-C-Token':
                process.env.REACT_APP_NODE_ENV === 'production'
                  ? ''
                  : process.env.REACT_APP_CAPTCHA_SKIP_KEY,
            },
          },
        );

        const accessToken = authUser.data?.access_token;

        if (accessToken) {
          api.defaults.headers.Authorization = `Bearer ${accessToken}`;

          setToken(accessToken);
          setAuthenticated(true);

          history.replace('/app');
        }
      } catch (err) {
        throw new Error(err);
      }
    },
    [setToken, history],
  );

  const checkRoles = useCallback(
    (roles: Roles[]): boolean => {
      if (roles.some((role) => user?.roles?.includes(role))) {
        return true;
      }

      const verifyHasRole = (rolesAccount: Roles[]): boolean => {
        return rolesAccount.some((roleAccount) => roles.includes(roleAccount));
      };

      if (user?.accounts?.some((account) => verifyHasRole(account.roles))) {
        return true;
      }

      return false;
    },
    [user.roles, user.accounts],
  );

  useEffect(() => {
    if (token) {
      api.defaults.headers.Authorization = `Bearer ${token}`;

      getAuth();
    } else {
      setValiding(false);

      setAuthenticated(false);

      setToken(null);

      setUser({} as UserAuth);

      delete api.defaults.headers.Authorization;
    }
  }, [token, getAuth, setToken]);

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        user,
        validing,
        token,
        loading,
        handleLogin,
        handleLogout,
        checkRoles,
        getAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
