import React from 'react';

import {
  MenuItemOption as ChakraMenuItem,
  MenuItemOptionProps,
} from '@chakra-ui/react';

export const MenuItemOption: React.FC<MenuItemOptionProps> = ({
  isChecked,
  ...props
}) => (
  <ChakraMenuItem
    borderRadius="base"
    fontSize="body-3"
    lineHeight="body-3"
    color={isChecked ? 'blue.500' : 'gray.700'}
    _hover={{
      bg: 'gray.50',
    }}
    isChecked={isChecked}
    {...props}
  />
);

export default MenuItemOption;
