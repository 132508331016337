import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useContext,
} from 'react';

import AuthContext from 'contexts/AuthContext';

import api from 'services/api';

const VendorContext = createContext(
  {} as {
    sendRequest: (
      endpoint: string,
      method: 'post' | 'delete' | 'patch' | 'get' | undefined,
      body?: undefined | any,
      body_type?: undefined | string,
    ) => Promise<any>;
    downloadFile: (endpoint: string) => void;
    queryVendorCourses: () => Promise<any>;
    queryVendorEventTypes: () => Promise<any>;
    queryVendorLeadContacts: (lead_id: number, type: string) => Promise<any>;
    queryVendorLeadRecentActivities: (lead_id: number) => Promise<any>;
  },
);

export const VendorProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [vendorId, setVendorId] = useState<any>();
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (user?.accounts?.length > 0) {
      setVendorId(user.accounts[0].id);
    }
  }, [user]);

  const sendRequest = async (
    endpoint: string,
    method: 'post' | 'delete' | 'patch' | 'get' | undefined,
    body = undefined,
    body_type = 'json',
  ): Promise<any> => {
    let urlEndpoint = endpoint;

    const mktPrefixes = [
      '/integrations',
      '/leads',
      '/lead-events',
      '/occurrences',
      '/abandoned-cart',
    ];
    if (
      vendorId &&
      mktPrefixes.some((prefix) => urlEndpoint.startsWith(prefix))
    ) {
      const url = new URL(endpoint, process.env.REACT_APP_API_URL);
      url.searchParams.set('vendor_id', vendorId);
      urlEndpoint = url.pathname + url.search;
    }

    try {
      const response = await api({
        method,
        url: urlEndpoint,
        data: body,
      });

      return {
        success: true,
        data: response.data,
      };
    } catch (exception) {
      return {
        success: false,
        data: 'Ocorreu um erro com sua requisição.',
      };
    }
  };

  const downloadFile = async (url: string): Promise<void> => {
    let urlEndpoint = url;
    if (urlEndpoint.includes('lead')) {
      if (!urlEndpoint.includes('?')) {
        urlEndpoint += '?';
      }
      urlEndpoint = `${urlEndpoint}&vendor_id=${vendorId}`;
    }

    const response = await api({
      method: 'get',
      url: urlEndpoint,
      responseType: 'blob',
    });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(response.data);
    link.download = 'seu-arquivo.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const queryVendorCourses = async (): Promise<any> => {
    const response = await sendRequest(`/course`, 'get');
    const { data } = response;

    return data.data.map((course: any) => {
      return {
        label: course.name,
        value: course.id,
      };
    });
  };

  const queryVendorEventTypes = async (): Promise<any> => {
    const response = await sendRequest(`/event-types`, 'get');
    const { data } = response;

    return data.map((event_type: any) => {
      return {
        label: event_type.nicename,
        value: event_type.id,
      };
    });
  };

  const queryVendorLeadContacts = async (
    lead_id: number,
    type: string,
  ): Promise<any> => {
    const response = await sendRequest(
      `/lead-events/${lead_id}/${type}`,
      'get',
    );
    const { data } = response;
    return data.data;
  };

  const queryVendorLeadRecentActivities = async (
    lead_id: number,
  ): Promise<any> => {
    const response = await sendRequest(`/lead-events/${lead_id}`, 'get');
    const { data } = response;
    return data.data;
  };

  return (
    <VendorContext.Provider
      value={{
        sendRequest,
        downloadFile,
        queryVendorCourses,
        queryVendorEventTypes,
        queryVendorLeadContacts,
        queryVendorLeadRecentActivities,
      }}
    >
      {children}
    </VendorContext.Provider>
  );
};

export default VendorContext;
