import { theme } from '@chakra-ui/react';

export const shadows = {
  ...theme.shadows,
  light: '0px 8px 16px 0px rgba(17, 20, 45, 0.08)',
  'lg.light': '0px 40px 32px -24px rgba(17, 20, 45, 0.12)',
  dropdown: '0px 40px 32px -24px rgba(17, 20, 45, 0.06)',
  menu: '0px 40px 32px -24px rgba(17, 20, 45, 0.08)',
  sidebar: '10px 0px 48px -24px rgba(17, 20, 45, 0.22)',
};
