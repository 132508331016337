import React from 'react';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';

import { Flex, Icon, useMediaQuery, CSSObject } from '@chakra-ui/react';

import { AngleLeft as AngleLeftIcon } from '@styled-icons/fa-solid/AngleLeft';
import { AngleRight as AngleRightIcon } from '@styled-icons/fa-solid/AngleRight';

type PaginationProps = {
  lastPage: number;
  forcePage?: number;
  onPaginate(selectedPage: number): void;
} & ReactPaginateProps;

const Pagination: React.FC<PaginationProps> = ({
  forcePage,
  lastPage,
  onPaginate,
  ...restProps
}) => {
  const [isLowerThan522] = useMediaQuery('(max-width: 552px)');

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      my={3}
      sx={paginationStyle}
    >
      <ReactPaginate
        previousLabel={<Icon as={AngleLeftIcon} />}
        nextLabel={<Icon as={AngleRightIcon} />}
        breakLabel="..."
        breakClassName="break-me"
        pageCount={lastPage}
        forcePage={forcePage}
        marginPagesDisplayed={isLowerThan522 ? 1 : 2}
        pageRangeDisplayed={isLowerThan522 ? 1 : 3}
        onPageChange={({ selected }) => {
          onPaginate(selected + 1);
        }}
        containerClassName="pagination"
        activeClassName="active"
        {...restProps}
      />
    </Flex>
  );
};

const paginationStyle: CSSObject = {
  '& ul.pagination': {
    boxSizing: 'border-box',
    listStyle: 'none',
    display: 'flex',
    justifyContent: 'center',

    '& li': {
      userSelect: 'none',
      direction: 'ltr',
      // boxSizing: 'border-box',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginX: 0.5,

      borderRadius: 'base',
      // borderRadius: '50%',

      height: '36px',
      minWidth: '36px',

      overflow: 'hidden',

      bg: 'blue.400',
      color: 'white',

      '&:not(.active):hover': {
        bg: 'blue.500',
      },

      '&.active': {
        bg: 'blue.700',

        // a: {
        //   color: '',
        // },
      },

      '&.previous': {
        marginRight: 1,
      },

      '&.next': {
        marginLeft: 1,
      },

      '& a': {
        // width: '100%',
        // height: '100%',
        textDecoration: 'none',
        color: 'black.100',
        padding: 2,
        fontWeight: 'bold',
      },
    },
  },
};

export default Pagination;
