import React from 'react';

import { Heading, HeadingProps, useMediaQuery } from '@chakra-ui/react';

const Subtitle: React.FC<HeadingProps> = (props) => {
  const [isLowerThan414] = useMediaQuery('(max-width: 414px)');

  return (
    <Heading
      variant="body-4"
      marginBottom={{ base: 2, sm: 0 }}
      flex="1"
      textAlign={isLowerThan414 ? 'center' : 'left'}
      // whiteSpace="nowrap"
      {...props}
    />
  );
};

export default Subtitle;
