import React from 'react';

import { Box, BoxProps } from '@chakra-ui/react';

const Container: React.FC<BoxProps> = (props) => {
  return (
    <Box
      width="100%"
      height="100%"
      // marginBottom={8}
      // marginTop={`${headerHeight}px`}
      position="relative"
      flex={1}
      // padding={4}
      {...props}
    />
  );
};

export default Container;
