import React, { useEffect } from 'react';

const GoogleRecaptchaV3: React.FC = () => {
  useEffect(() => {
    if (!process.env.REACT_APP_CAPTCHA_TOKEN) {
      return undefined;
    }

    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_CAPTCHA_TOKEN}`;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null;
};

export default GoogleRecaptchaV3;
