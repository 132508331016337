import React from 'react';
import { components, DropdownIndicatorProps } from 'react-select';

import DropdownIndicatorIcon from 'components/DropdownIndicator';

const DropdownIndicator: React.FC<DropdownIndicatorProps> = (props) => {
  const { selectProps } = props;

  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <DropdownIndicatorIcon isOpen={selectProps.menuIsOpen} />
      </components.DropdownIndicator>
    )
  );
};

export default DropdownIndicator;
