import { useContext } from 'react';

import SidebarContext, { ISidebar } from 'contexts/SidebarContext';

function useSidebar(): ISidebar {
  const sidebar = useContext(SidebarContext);

  if (!sidebar) {
    throw new Error('You have to use "useSidebar()" inside SidebarProvider');
  }

  return sidebar;
}

export { SidebarProvider } from 'contexts/SidebarContext';

export default useSidebar;
