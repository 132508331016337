export const layerStyles = {
  bgGradient500: {
    backgroundImage: 'linear-gradient(224deg, #86E1A0 0%, #4FBF67 100%)',
  },
  bgGradient400: {
    backgroundImage: 'linear-gradient(133deg, #4FBF67, #584ABD)',
  },
  bgGradient300: {
    backgroundImage: 'linear-gradient(224deg, #78A0FF, #3266E3)',
  },
  bgGradient200: {
    backgroundImage: 'linear-gradient(90deg, #6C5DD3, #FF98E5)',
  },
  bgGradient100: {
    backgroundImage: 'linear-gradient(45deg, #11142D, #6C5DD3)',
  },
};
