import { ComponentStyleConfig } from '@chakra-ui/react';

// export const Menu: ComponentStyleConfig = {
//   baseStyle: {
//     // fontWeight: 500,
//     bg: 'blue',
//   },
//   defaultProps: {
//     colorScheme: 'blue',
//   },
// };

export const MenuList: ComponentStyleConfig = {
  defaultProps: {
    bg: 'red',
  },
};
