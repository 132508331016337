import { ComponentStyleConfig } from '@chakra-ui/react';

import { allTextVariants } from './textStyles';

export const Text: ComponentStyleConfig = {
  ...allTextVariants,

  defaultProps: {
    variant: 'body-3',
  },
};
