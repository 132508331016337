import React from 'react';

import { useMenuContext, Box } from '@chakra-ui/react';
import { cx } from '@chakra-ui/utils';

export type MenuArrowProps = HTMLChakraProps<'div'>;

export const MenuArrow: React.FC<MenuArrowProps> = ({
  className,
  ...props
}) => {
  const { popper } = useMenuContext();

  const { getArrowProps, getPopperProps } = popper;

  return (
    <Box
      className={cx('chakra-popover__arrow-positioner', className)}
      {...getPopperProps(props)}
    >
      <Box
        className={cx('chakra-popover__arrow', className)}
        {...getArrowProps(props)}
        _after={{
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          bg: '#FFF',
          transform: 'rotate(45deg)',
          borderColor: 'gray.100',
          borderWidth: '1px',
          boxShadow: 'light',
        }}
      />
    </Box>
  );
};
