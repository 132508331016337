import { useContext } from 'react';

import AuthContext, { Auth } from 'contexts/AuthContext';

function useAuth(): Auth {
  const auth = useContext(AuthContext);

  if (!auth) {
    throw new Error('You have to use "useAuth()" inside AuthProvider');
  }

  return auth;
}

export { AuthProvider } from 'contexts/AuthContext';

export default useAuth;
