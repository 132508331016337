import React, { ReactElement, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import {
  motion,
  useAnimation,
  AnimatePresence,
  MotionProps,
} from 'framer-motion';

import {
  TableRowProps,
  Tr as ChakraTr,
  useBreakpointValue,
  useToken,
} from '@chakra-ui/react';

import TableContext from 'contexts/TableContext';

interface ITrInnerProps extends TableRowProps {
  headers: Record<number, React.ReactElement>;
  bodies: Record<number, React.ReactElement>;
  inHeader: boolean;
  inBody: boolean;
  bodyidx: number;
}

export type ITrProps = Omit<ITrInnerProps, 'headers', 'bodies'>;

const MotionTr = motion<MotionProps & TableRowProps>(ChakraTr);

const TrInner = (props: ITrInnerProps): React.ReactElement => {
  const [ref, inView] = useInView({ threshold: 0.4 });
  const animation = useAnimation();

  const [colorGray50] = useToken('colors', ['gray.50']);

  const {
    headers,
    bodies,
    children,
    inHeader,
    inBody,
    bodyidx,
    ...rest
  } = props;

  const breakPointIsAvailable = useBreakpointValue({
    '80em': { isAvailable: true },
  });

  if (headers && inHeader && !breakPointIsAvailable?.isAvailable) {
    React.Children.map(props.children, (child, i) => {
      if (child) {
        headers[i] = (child as ReactElement).props.children;
      }
    });
  }

  if (bodies && inBody) {
    React.Children.map(props.children, (child, i) => {
      if (child) {
        bodies[i] = (child as ReactElement).props.children;
      }
    });
  }

  const childProps = (idx: number): React.ReactElement =>
    inHeader
      ? { key: idx }
      : { key: idx, columnKey: idx, numberOfColumns: children.length };

  useEffect(() => {
    if (inView) {
      animation.start('visible');
    }
  }, [animation, inView, inBody]);

  return (
    <AnimatePresence>
      <MotionTr
        {...rest}
        ref={ref}
        animate={animation}
        initial={inBody ? 'hidden' : 'visible'}
        position="relative"
        variants={{
          visible: {
            opacity: 1,
            // scale: 1,
            // top: 0,
            transition: {
              duration: 0.4 + (bodyidx || 0) / 100,
            },
          },
          hidden: {
            opacity: 0.1,
            // scale: 0.95,
            // top: -10,
          },
        }}
        sx={{
          ...(inBody && {
            '&:nth-of-type(2n+1)': {
              bg: `${colorGray50} !important`,
            },
          }),
        }}
        // initial={{
        //   opacity: 0,
        //   height: 0,
        // }}
        // animate={{
        //   opacity: 1,
        //   // scale: 1,
        //   height: 'auto',
        //   transition: {
        //     duration: 0.25 + (bodyidx || 0) / 100,
        //   },
        // }}
        // exit={{
        //   opacity: 0,
        //   height: 0,
        //   transition: {
        //     duration: 0.25 + (bodyidx || 0) / 100,
        //   },
        // }}

        // {...(inBody && {
        //   // animate: { opacity: [0.1, 1], scale: [0.95, 1] },
        //   // transition: { duration: 0.15 * (bodyidx || 0) },

        //   variants: {
        //     visible: {
        //       opacity: 1,
        //       transition: {
        //         duration: 0.15 * (bodyidx || 0),
        //       },
        //     },
        //     hidden: {
        //       opacity: 0.1,
        //       scale: 0.95,
        //     },
        //   },
        // })}
      >
        {children &&
          React.Children.map(children, (child, idx) => {
            return (
              React.isValidElement(child) &&
              React.cloneElement(child, {
                ...childProps(idx),
              })
            );
          })}
      </MotionTr>
    </AnimatePresence>
  );
};

export const Tr = (props: ITrProps): React.ReactElement => {
  return (
    <TableContext.Consumer>
      {(headers, bodies) => (
        <TrInner {...props} headers={headers} bodies={bodies} />
      )}
    </TableContext.Consumer>
  );
};
