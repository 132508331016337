import React, { createContext, useCallback } from 'react';

import usePersistedState from 'hooks/usePersistedState';

export interface ITimezoneContext {
  zone: string;
  setActiveTimezone: (zoneName: string) => void;
}

const TimezoneContext = createContext({} as ITimezoneContext);

export const TimezoneProvider: React.FC = ({ children }) => {
  const [zone, setZone] = usePersistedState<string>(
    'activeTimezone',
    'America/Sao_Paulo',
  );

  const setActiveTimezone = useCallback(
    (zoneName: string) => {
      setZone(zoneName);
    },
    [setZone],
  );

  return (
    <TimezoneContext.Provider
      value={{
        zone,
        setActiveTimezone,
      }}
    >
      {children}
    </TimezoneContext.Provider>
  );
};

export default TimezoneContext;
