import React from 'react';

import {
  MenuItem as ChakraMenuItem,
  MenuItemProps as ChakraMenuItemProps,
} from '@chakra-ui/react';

export interface MenuItemProps extends ChakraMenuItemProps {
  isActive: boolean;
}

export const MenuItem: React.FC<MenuItemProps> = ({ isActive, ...props }) => (
  <ChakraMenuItem
    borderRadius="base"
    fontSize="body-3"
    lineHeight="body-3"
    color={isActive ? 'blue.500' : 'gray.700'}
    _hover={{
      bg: 'gray.50',
    }}
    {...props}
  />
);

export default MenuItem;
