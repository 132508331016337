import { ComponentStyleConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

export const Table: ComponentStyleConfig = {
  variants: {
    cursology: (props) => ({
      thead: {
        th: {
          paddingTop: 3,
          paddingBottom: 3,
          color: mode('black', 'black')(props),
        },
      },
      tbody: {
        tr: {
          _odd: {
            backgroundColor: 'gray.50',
          },
          td: {
            color: mode('black', 'black')(props),
          },
        },
      },
    }),
  },
  defaultProps: {
    size: 'sm',
    variant: 'cursology',
  },
};
