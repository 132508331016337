import React from 'react';
import ReactJson from 'react-json-view';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Button,
} from '@chakra-ui/react';

import { OrderWebhookHistory } from '@types/order';

import { ModalRootProps } from 'components/Modal/Root';

interface ModalWebhookPayloadViewerProps extends ModalRootProps {
  data: OrderWebhookHistory;
}

const ModalWebhookPayloadViewer: React.FC<ModalWebhookPayloadViewerProps> = ({
  data = {},
  // onConfirm,
  handleClose,
  ...restProps
}) => {
  // const handleConfirm = (account: Account): void => {
  //   if (onConfirm && typeof onConfirm === typeof Function) {
  //     onConfirm(account);
  //     handleClose();
  //   }
  // };

  return (
    <Modal {...restProps} scrollBehavior="inside" size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Payload do evento</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <ReactJson displayDataTypes={false} src={data} />
        </ModalBody>

        <ModalFooter>
          <Button size="sm" onClick={handleClose}>
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalWebhookPayloadViewer;
