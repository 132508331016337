import React, { useState } from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  HStack,
  Text,
  useToast,
} from '@chakra-ui/react';

import api from 'services/api';

import { ModalRootProps } from 'components/Modal/Root';

const ModalRemoveCheckout: React.FC<ModalRootProps> = ({
  data,
  onConfirm,
  handleClose,
  ...restProps
}) => {
  const toast = useToast();

  const [loading, setLoading] = useState(false);

  const handleConfirm = async (id: number): Promise<void> => {
    setLoading(true);

    try {
      await api.delete(`/paylink/${id}`);

      toast({
        title: 'Checkout removido com sucesso!',
        status: 'success',
        variant: 'solid',
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });

      if (onConfirm) onConfirm();
    } catch (err) {
      toast({
        title: 'Erro ao remover o checkout!',
        status: 'error',
        variant: 'solid',
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  return (
    <Modal {...restProps}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Remover checkout</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Text mb={3}>
            Você realmente deseja remover o checkout{' '}
            <Text fontWeight="bold" as="span">
              {data.name}
            </Text>
            ?
          </Text>
        </ModalBody>

        <ModalFooter>
          <HStack spacing="2">
            <Button colorScheme="red" onClick={handleClose}>
              Não
            </Button>

            <Button
              colorScheme="green"
              onClick={() => handleConfirm(data.id)}
              isLoading={loading}
            >
              Sim
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalRemoveCheckout;
