import { ComponentStyleConfig } from '@chakra-ui/react';

export const Button: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: 48,
    // fontWeight: 500,
  },
  defaultProps: {
    colorScheme: 'blue',
  },
};

export const IconButton: ComponentStyleConfig = {
  defaultProps: {
    isRound: true,
  },
};
