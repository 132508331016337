import React, { useState, useCallback, useEffect, useMemo } from 'react';

import { format } from 'date-fns';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Text,
} from '@chakra-ui/react';

import {
  Pagination as PaginationType,
  ServicePagination,
} from '@types/pagination';
import { TransferHistory } from '@types/transfer';

import api from 'services/api';

import Loader from 'components/Loader';
import { ModalRootProps } from 'components/Modal/Root';
import { Content } from 'components/Page';
import Pagination from 'components/Pagination';
import { Table, Thead, Tbody, Tr, Th, Td } from 'components/Table';

import useThrottledState from 'hooks/useThrottledState';

export interface TransferHistoryProps extends ModalRootProps {
  data: {
    transfer_id: number;
  };
}

const ModalTransferHistory: React.FC<TransferHistoryProps> = ({
  data: initialData,
  ...restProps
}) => {
  const [data, setData] = useState<TransferHistory[]>([]);
  const [loading, setLoading] = useState(false);

  const [pagination, setPagination] = useThrottledState<PaginationType>(
    {
      per_page: 10,
      page: 1,
    },
    1000,
  );

  const [servicePagination, setServicePagination] = useState<ServicePagination>(
    { last_page: 1 },
  );

  const searchParams = useMemo(() => {
    return {
      page: pagination.page,
      per_page: pagination.per_page,
      sort: '-created_at',
    };
  }, [pagination]);

  const getData = useCallback(async () => {
    setLoading(true);
    setData([]);

    try {
      const response = await api.get(
        `/transfer/${initialData.transfer_id}/history`,
        {
          params: searchParams,
        },
      );

      const transferHistory = response.data;

      setData(transferHistory.data);
      setServicePagination(transferHistory.meta);
    } catch (err) {
      setServicePagination({ last_page: 1 });
    } finally {
      setLoading(false);
    }
  }, [initialData, searchParams]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Modal {...restProps} scrollBehavior="inside" size="auto">
      <ModalOverlay />
      <ModalContent width="1000px" maxWidth="90%">
        <ModalHeader>Histórico de transferência</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Content noPadding>
            <Table>
              <Thead>
                <Tr>
                  <Th>Data</Th>
                  <Th>Responsável</Th>
                  <Th>Ação</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!loading &&
                  data.map((item) => (
                    <Tr key={item.id}>
                      <Td>
                        {format(
                          new Date(item.created_at),
                          'dd/MM/yyyy HH:mm:ss',
                        )}
                      </Td>

                      <Td>{item.user.name}</Td>

                      <Td whiteSpace="normal !important">
                        <Text>{item.message}</Text>
                      </Td>
                    </Tr>
                  ))}

                {loading && (
                  <Tr>
                    <Td noData colSpan={1000}>
                      <Loader />
                    </Td>
                  </Tr>
                )}

                {!loading && data.length === 0 && (
                  <Tr>
                    <Td noData colSpan={4} textAlign="center" lineHeight="150%">
                      Nenhum resultado encontrado.
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </Content>
        </ModalBody>

        <ModalFooter alignItems="center" justifyContent="center">
          <Pagination
            lastPage={servicePagination.last_page}
            onPaginate={(selectedPage) => {
              setPagination({ ...pagination, page: selectedPage });
            }}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalTransferHistory;
