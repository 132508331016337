import React from 'react';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter, Router } from 'react-router-dom';

import { createBrowserHistory } from 'history';
import Routes from 'routes';

import { ChakraProvider } from '@chakra-ui/react';

import AlertRoot from 'components/Alert/Root';
import ModalRoot from 'components/Modal/Root';

import { VendorProvider } from 'contexts-mkt/vendorContext';
import { AlertProvider } from 'contexts/AlertContext';
import { CheckoutProvider } from 'contexts/CheckoutContext';
import { ManagedAccountProvider } from 'contexts/ManagedAccountContext';
import { ModalProvider } from 'contexts/ModalContext';
import { TimezoneProvider } from 'contexts/TimezoneContext';

import { AuthProvider } from 'hooks/useAuth';

import customTheme from 'styles/theme/customTheme';

import 'react-perfect-scrollbar/dist/css/styles.css';
import GoogleRecaptchaV3 from 'components/Google/GoogleRecaptchaV3';
import GoogleTagManager from 'components/Google/GoogleTagManager';

const history = createBrowserHistory();

const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <>
      <GoogleRecaptchaV3 />
      <GoogleTagManager />
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={customTheme}>
          <Router history={history}>
            <BrowserRouter>
              <AuthProvider>
                <CheckoutProvider>
                  <TimezoneProvider>
                    <AlertProvider>
                      <ModalProvider>
                        <ManagedAccountProvider>
                          <VendorProvider>
                            <Routes />
                            <ModalRoot />
                          </VendorProvider>
                        </ManagedAccountProvider>
                      </ModalProvider>
                      <AlertRoot />
                    </AlertProvider>
                  </TimezoneProvider>
                </CheckoutProvider>
              </AuthProvider>
            </BrowserRouter>
          </Router>
        </ChakraProvider>

        <ReactQueryDevtools position="bottom-right" />
      </QueryClientProvider>
    </>
  );
};

export default App;
