import React, { useState, useCallback, useEffect, useMemo } from 'react';

import { format } from 'date-fns';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Button,
  Text,
} from '@chakra-ui/react';

import { CheckoutWebhookHistory } from '@types/checkout';
import {
  Pagination as PaginationType,
  ServicePagination,
} from '@types/pagination';

import api from 'services/api';

import Loader from 'components/Loader';
import { ModalRootProps } from 'components/Modal/Root';
import { Content } from 'components/Page';
import Pagination from 'components/Pagination';
import StatusCircle from 'components/StatusCircle';
import { Table, Thead, Tbody, Tr, Th, Td } from 'components/Table';
import Tooltip from 'components/Tooltip';

import useModal from 'hooks/useModal';
import useThrottledState from 'hooks/useThrottledState';

export interface CheckoutWebhookHistoryProps extends ModalRootProps {
  data: {
    checkout_id: number;
    checkout_type: 'classroom' | 'product';
    description?: string;
  };
}

const ModalCheckoutWebhookHistory: React.FC<CheckoutWebhookHistoryProps> = ({
  data: initialData,
  ...restProps
}) => {
  const { openModal } = useModal();

  const [data, setData] = useState<CheckoutWebhookHistory[]>([]);
  const [loading, setLoading] = useState(false);

  const [pagination, setPagination] = useThrottledState<PaginationType>(
    {
      per_page: 10,
      page: 1,
    },
    1000,
  );

  const [servicePagination, setServicePagination] = useState<ServicePagination>(
    { last_page: 1 },
  );

  const canSearch = initialData?.checkout_id && initialData?.checkout_type;

  const searchParams = useMemo(() => {
    return {
      page: pagination.page,
      per_page: pagination.per_page,
      sort: '-created_at',
      'filter[owner_type]': initialData.checkout_type,
      'filter[owner_id]': initialData.checkout_id,
    };
  }, [initialData, pagination]);

  const getData = useCallback(async () => {
    if (!canSearch) {
      return;
    }

    setLoading(true);
    setData([]);

    try {
      const response = await api.get(`/webhook`, {
        params: searchParams,
      });

      const historyDelivery = response.data;

      setData(historyDelivery.data);
      setServicePagination(historyDelivery.meta);
    } catch (err) {
      setServicePagination({ last_page: 1 });
    } finally {
      setLoading(false);
    }
  }, [canSearch, searchParams]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Modal {...restProps} scrollBehavior="inside" size="auto">
      <ModalOverlay />
      <ModalContent maxWidth="90%">
        <ModalHeader>
          Histórico de eventos
          <Text variant="text-sm" fontWeight="normal">
            {initialData.description}
          </Text>
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Content noPadding>
            <Table>
              <Thead>
                <Tr>
                  <Th>Data</Th>
                  <Th>Plataforma</Th>
                  <Th>Situação</Th>
                  <Th>Tipo</Th>
                  <Th>URL</Th>
                  <Th>Payload</Th>
                  <Th>Detalhes</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!loading &&
                  data.map((item) => (
                    <Tr key={item.id}>
                      <Td>
                        {format(
                          new Date(item.created_at),
                          'dd/MM/yyyy HH:mm:ss',
                        )}
                      </Td>

                      <Td cursor="default">
                        <Tooltip hasArrow label={item.platform_key}>
                          {item.platform_name}
                        </Tooltip>
                      </Td>

                      <Td>
                        {(() => {
                          if (item.status === 'success') {
                            return (
                              <StatusCircle colorScheme="green">
                                Sucesso
                              </StatusCircle>
                            );
                          }

                          if (item.status === 'failed') {
                            return (
                              <StatusCircle colorScheme="red">
                                Com falha
                              </StatusCircle>
                            );
                          }

                          if (item.status === 'error') {
                            return (
                              <StatusCircle colorScheme="red">
                                Com erro
                              </StatusCircle>
                            );
                          }

                          if (item.status === 'pending') {
                            return (
                              <StatusCircle colorScheme="yellow">
                                Pendente
                              </StatusCircle>
                            );
                          }

                          if (item.status === 'legacy') {
                            return (
                              <StatusCircle colorScheme="gray">
                                Sem integração
                              </StatusCircle>
                            );
                          }

                          return (
                            <StatusCircle colorScheme="gray">
                              Sem integração
                            </StatusCircle>
                          );
                        })()}
                      </Td>

                      <Td whiteSpace="normal !important">
                        <Text
                          overflowWrap="break-word"
                          whiteSpace="normal !important"
                          fontWeight="bold"
                        >
                          {item.event_type}
                        </Text>
                      </Td>

                      <Td>
                        <Text
                          // overflowWrap="break-word"
                          whiteSpace="break-spaces"
                          wordBreak="break-word"
                          // wordBreak="break-all"
                        >{`${item.http_verb?.toUpperCase()} ${
                          item.request_url
                        }`}</Text>
                      </Td>

                      <Td>
                        <Button
                          size="sm"
                          variant="link"
                          onClick={() =>
                            openModal('webhook-payload-viewer', {
                              data: item,
                            })
                          }
                        >
                          Ver payload
                        </Button>
                      </Td>

                      <Td>
                        <Button
                          size="sm"
                          colorScheme="gray"
                          onClick={() =>
                            openModal('webhook-history-delivery', {
                              data: {
                                webhook_id: item.id,
                              },
                            })
                          }
                        >
                          Detalhes
                        </Button>
                      </Td>
                    </Tr>
                  ))}

                {loading && (
                  <Tr>
                    <Td noData colSpan={1000}>
                      <Loader />
                    </Td>
                  </Tr>
                )}

                {!loading && data.length === 0 && (
                  <Tr>
                    <Td noData colSpan={1000} className="text-center">
                      Nenhum resultado encontrado. Aguarde alguns minutos que o
                      processamento será finalizado.
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </Content>
        </ModalBody>

        <ModalFooter alignItems="center" justifyContent="center">
          <Pagination
            lastPage={servicePagination.last_page}
            onPaginate={(selectedPage) => {
              setPagination({ ...pagination, page: selectedPage });
            }}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalCheckoutWebhookHistory;
