import { useEffect } from 'react';

const GoogleTagManager: React.FC = () => {
  useEffect(() => {
    if (!process.env.REACT_APP_TAG_MANAGER) {
      return undefined;
    }

    const script = document.createElement('script');
    script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${process.env.REACT_APP_TAG_MANAGER}');
    `;
    document.head.appendChild(script);

    const iframe = document.createElement('noscript');
    iframe.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${process.env.REACT_APP_TAG_MANAGER}"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;
    document.body.appendChild(iframe);

    return () => {
      document.head.removeChild(script);
      document.body.removeChild(iframe);
    };
  }, []);

  return null;
};

export default GoogleTagManager;
