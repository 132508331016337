import { ComponentStyleConfig } from '@chakra-ui/react';

export const Tooltip: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: 'base',
    padding: 2,
  },
  defaultProps: {
    bg: 'gray.800',
    color: 'white',
  },
};
