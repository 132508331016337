import React from 'react';

import {
  TableCellProps,
  Td as ChakraTd,
  Text,
  CSSObject,
} from '@chakra-ui/react';

import TableContext from 'contexts/TableContext';

import { tableBodyMediaQueryText } from 'shared/table/mediaQuery';

interface IResponsive {
  columnName?: string;
  order: number;
  gridColumn?: string;
  gridRow?: string;
  gridColumnStart?: string | number;
  gridColumnEnd?: string | number;
  gridRowStart?: string | number;
  gridRowEnd?: string | number;
}

interface ITdInnerProps extends TableCellProps {
  columnKey: number;
  numberOfColumns: number;
  narrowHeaders: Record<number, React.ReactElement>;
  responsive: IResponsive;
  isAction: boolean;
  noData: boolean;
}

export type ITdProps = Omit<ITdInnerProps, 'narrowHeaders'>;

const TdInner = ({
  // className,
  children,
  ...restProps
}: ITdInnerProps): React.ReactElement => {
  const {
    narrowHeaders = {},
    columnKey = 0,
    numberOfColumns,
    isAction,
    noData,
    ...rest
  } = restProps;

  return (
    <ChakraTd
      data-testid="td"
      {...rest}
      sx={{
        ...styles,
        ...(isAction && {
          gridRowStart: 'auto',
          gridRowEnd: numberOfColumns + 1,
        }),
      }}
    >
      {!noData && (
        <Text
          data-testid="td-before"
          display="none"
          variant="smallcaps"
          color="gray.700"
          sx={{
            [`@media screen and ${tableBodyMediaQueryText}`]: {
              '&': {
                display: 'block',
                // textTransform: 'uppercase',
                marginBottom: 1,
                // fontSize: 'xs',
                whiteSpace: 'pre-wrap',
                overflowWrap: 'break-word',
                textAlign: 'left !important',
                // fontWeight: 'semibold',
              },
            },
          }}
        >
          {restProps?.responsiveColumnName ?? narrowHeaders[columnKey]}
        </Text>
      )}

      {children ?? <div>&nbsp;</div>}
    </ChakraTd>
  );
};

export const Td = (props: ITdProps): ChakraTd => (
  <TableContext.Consumer>
    {(headers) => {
      return <TdInner {...props} narrowHeaders={headers} />;
    }}
  </TableContext.Consumer>
);

const styles: CSSObject = {
  [`@media screen and ${tableBodyMediaQueryText}`]: {
    // width: '100%',
    // height: '100%',
    display: 'flex',
    // flexBasis: 1,
    justifyContent: 'center',
    alignItems: 'center',

    // ...(responsive && {
    //   gridColumn: responsive?.gridColumn ?? undefined,
    //   gridRow: responsive?.griRow ?? undefined,
    //   gridColumnStart: responsive?.gridColumnStart ?? undefined,
    //   gridColumnEnd: responsive?.gridColumnEnd ?? undefined,
    //   gridRowStart: responsive?.gridRowStart ?? undefined,
    //   gridRowEnd: responsive?.gridRowEnd ?? undefined,
    // }),

    border: 'none',
    textAlign: 'left !important',
    whiteSpace: 'nowrap',
    overflowWrap: 'break-word',
    overflow: 'hidden',
  },
};
