export const bankAccountTypes = [
  {
    value: 'conta_corrente',
    label: 'Conta Corrente',
  },
  {
    value: 'conta_poupanca',
    label: 'Conta Poupança',
  },
  {
    value: 'conta_corrente_conjunta',
    label: 'Conta Corrente Conjunta',
  },
  {
    value: 'conta_poupanca_conjunta',
    label: 'Conta Poupança Conjunta',
  },
];

export const documentTypes = [
  {
    value: 'cpf',
    label: 'CPF',
  },
  {
    value: 'cnpj',
    label: 'CNPJ',
  },
];
